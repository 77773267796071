import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import { BlueOnClick } from "../common/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TotalWaterUse } from "./models";
import { createSelector } from "../common/orm";
import HelpLabel from "../common/HelpLabel";
import NumberFormat from "react-number-format";
/* Calc Method Start */
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const getOptions = TotalWaterUse.getOptions();

const CalcMethod = (props) => {
  // props: fieldName, currentValue
  const [value, setValue] = React.useState(props.currentValue); // eslint-disable-line no-unused-vars

  /* TODO: Figure out how to set the field's value so that the value of the field and make sure the form can read it */
  return (
    <FormControl component="fieldset">
      <RadioGroup
        style={{ flexWrap: "nowrap", marginLeft: "10px" }}
        aria-label="direct"
        name={props.fieldName}
        row
      >
        <FormControlLabel
          value="Direct"
          disabled={props.disabled}
          onChange={props.onChange}
          style={{ alignItems: "center" }}
          control={
            <Radio
              checked={props.value === "Direct"}
              style={{
                padding: "6px 0px 3px 7px",
                color: props.value === "Direct" ? "primary" : "",
              }}
            />
          }
          label="Direct"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Estimated"
          disabled={props.disabled}
          onChange={props.onChange}
          style={{ alignItems: "center" }}
          control={
            <Radio
              checked={props.value === "Estimated"}
              style={{
                padding: "6px 0 3px 7px",
                color: props.value === "Estimated" ? "primary" : "",
              }}
            />
          }
          label="Estimated"
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};
/* Calc Method End */

const getUserData = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["gsp"]),
  }),
  (session, options) => {
    let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];

    let association = session.GSPAssociation.filter(
      (u) => u.user === options.id && u.gsp === options.gsp
    ).toRefArray()[0];

    let gsp = association
      ? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
      : {};

    return user && association && gsp
      ? {
          ...user,
          association: association ? association : {},
          gsp: gsp ? gsp : {},
        }
      : {};
  }
);

function formatDate(date) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var day = date.getUTCDate();
  var monthIndex = date.getUTCMonth();
  var year = date.getUTCFullYear();

  return monthNames[monthIndex] + " " + day + ", " + year;
}

const getWaterYear = createSelector((session, id) => {
  let wy = session.WaterYear.filter(
    (y) => y.is_active === true
  ).toRefArray()[0];
  return {
    ...wy,
  };
});

const currentTotalWaterUse = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["gsp"]),
    twu_id: parseInt(ownProps.match.params["id"]),
  }),
  (session, options) => {
    let gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];

    let thisWaterYear = session.WaterYear.filter(
      (y) => y.is_active === true
    ).toRefArray()[0];

    if (options.twu_id) {
      let twu = session.TotalWaterUse.filter(
        (y) => y.gsp === gsp.id && y.id === options.twu_id
      ).toRefArray()[0];

      let wy =
        twu && twu.water_year_id
          ? session.WaterYear.filter(
              (y) => y.id === twu.water_year_id
            ).toRefArray()[0]
          : {};

      twu = { ...twu, wy: wy };

      return gsp && twu && wy ? twu : {};
    } else {
      return gsp && thisWaterYear
        ? session.TotalWaterUse.filter(
            (y) => y.water_year_id === thisWaterYear.id && y.gsp === gsp.id
          ).toRefArray()[0]
        : {};
    }
  }
);

const historicTotalWaterUse = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["gsp"]),
  }),
  (session, options) => {
    let association = session.GSPAssociation.filter(
      (u) => u.user === options.id && u.gsp === options.gsp
    ).toRefArray()[0];

    let gsp = association
      ? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
      : {};

    let thisWaterYear = session.WaterYear.filter(
      (y) => y.is_active === true
    ).toRefArray()[0];

    return thisWaterYear && gsp
      ? session.TotalWaterUse.filter(
          (y) => y.water_year_id !== thisWaterYear.id && y.gsp === gsp.id
        ).toRefArray()
      : {};
  }
);

const styles = (theme) => ({
  container: {
    paddingLeft: "10px",
  },
  hrclass: {
    borderColor: "primary",
  },
  tc: {
    padding: "4px 4px 4px 4px",
    maxWidth: "60px",
  },
  tcNumber: {
    padding: "4px 4px 4px 4px",
    maxWidth: "40px",
  },
  rightAligned: {
    textAlign: "right",
    padding: "4px 4px 4px 4px",
    maxWidth: "40px",
  },
  tcAccuracy: {
    padding: "4px 4px 4px 15px",
    maxWidth: "20px",
  },
  mdHeader: {
    fontSize: "18px",
    textDecoration: "underline",
    fontWeight: "normal",
  },
  lgHeader: {
    fontSize: "25px",
  },
  buttonActive: {
    backgroundColor: theme.palette.primary.dark + " !important",
    color: theme.palette.primary.contrastText + " !important",
  },
  buttonInactive: {
    backgroundColor: theme.palette.primary.light + " !important",
    color: theme.palette.primary.contrastText + " !important",
  },
});

class ReportingTotalWaterUse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csrftoken: localStorage.auth_token,
      loader: null,
      agency: "Not Available",
      waterYearStart: "Not Available",
      waterYearEnd: "Not Available",
      districtName: "Not Available",
      helpOpen: false,
      callOptions: false,

      import_cv_total: "",
      import_sw_total: "",
      import_l_total: "",
      riv_total: "",
      creek_total: "",
      gw_total: "",
      recycled_total: "",
      reused_total: "",
      other_total: "",
      total_total: "",

      // Default Values
      t_dom_import_cv: 0,
      t_dom_import_sw: 0,
      t_dom_import_l: 0,
      t_dom_riv: 0,
      t_dom_creek: 0,
      t_dom_gw: 0,
      t_dom_recycled: 0,
      t_dom_reused: 0,
      t_dom_other: 0,
      t_dom_total: 0,
      t_dom_acc: null,
      t_dom_calcm: null,
      t_dom_notes: null,

      t_ind_import_cv: 0,
      t_ind_import_sw: 0,
      t_ind_import_l: 0,
      t_ind_riv: 0,
      t_ind_creek: 0,
      t_ind_gw: 0,
      t_ind_recycled: 0,
      t_ind_reused: 0,
      t_ind_other: 0,
      t_ind_total: 0,

      t_agg_import_cv: 0,
      t_agg_import_sw: 0,
      t_agg_import_l: 0,
      t_agg_riv: 0,
      t_agg_creek: 0,
      t_agg_gw: 0,
      t_agg_recycled: 0,
      t_agg_reused: 0,
      t_agg_other: 0,
      t_agg_total: 0,

      t_wet_import_cv: 0,
      t_wet_import_sw: 0,
      t_wet_import_l: 0,
      t_wet_riv: 0,
      t_wet_creek: 0,
      t_wet_gw: 0,
      t_wet_recycled: 0,
      t_wet_reused: 0,
      t_wet_other: 0,
      t_wet_total: 0,

      t_dist_man_import_cv: 0,
      t_dist_man_import_sw: 0,
      t_dist_man_import_l: 0,
      t_dist_man_riv: 0,
      t_dist_man_creek: 0,
      t_dist_man_gw: 0,
      t_dist_man_recycled: 0,
      t_dist_man_reused: 0,
      t_dist_man_other: 0,
      t_dist_man_total: 0,

      t_in_lieu_import_cv: 0,
      t_in_lieu_import_sw: 0,
      t_in_lieu_import_l: 0,
      t_in_lieu_riv: 0,
      t_in_lieu_creek: 0,
      t_in_lieu_gw: 0,
      t_in_lieu_recycled: 0,
      t_in_lieu_reused: 0,
      t_in_lieu_other: 0,
      t_in_lieu_total: 0,

      t_veg_import_cv: 0,
      t_veg_import_sw: 0,
      t_veg_import_l: 0,
      t_veg_riv: 0,
      t_veg_creek: 0,
      t_veg_gw: 0,
      t_veg_recycled: 0,
      t_veg_reused: 0,
      t_veg_other: 0,
      t_veg_total: 0,

      t_out_import_cv: 0,
      t_out_import_sw: 0,
      t_out_import_l: 0,
      t_out_riv: 0,
      t_out_creek: 0,
      t_out_gw: 0,
      t_out_recycled: 0,
      t_out_reused: 0,
      t_out_other: 0,
      t_out_total: 0,

      t_other_import_cv: 0,
      t_other_import_sw: 0,
      t_other_import_l: 0,
      t_other_riv: 0,
      t_other_creek: 0,
      t_other_gw: 0,
      t_other_recycled: 0,
      t_other_reused: 0,
      t_other_other: 0,
      t_other_total: 0,

      t_total_total: 0,

      water_year_id: null,
      gsp_id: null,
      curdata: false,

      editable: false,
    };
  }

  componentDidMount() {
    const { authState, history, watercur } = this.props;
    // if (authState && authState.user && authState.user.role === "Agency") {
    //   history.push("/dashboard");
    // }

    if (watercur) {
      this.setState({
        import_cv_total:
          this.pf(this.state.t_agg_import_cv) +
          this.pf(this.state.t_dom_import_cv) +
          this.pf(this.state.t_ind_import_cv) +
          this.pf(this.state.t_wet_import_cv) +
          this.pf(this.state.t_out_import_cv) +
          this.pf(this.state.t_veg_import_cv) +
          this.pf(this.state.t_other_import_cv) +
          this.pf(this.state.t_dist_man_import_cv) +
          this.pf(this.state.t_in_lieu_import_cv),
        import_sw_total:
          this.pf(this.state.t_agg_import_sw) +
          this.pf(this.state.t_dom_import_sw) +
          this.pf(this.state.t_ind_import_sw) +
          this.pf(this.state.t_wet_import_sw) +
          this.pf(this.state.t_out_import_sw) +
          this.pf(this.state.t_veg_import_sw) +
          this.pf(this.state.t_other_import_sw) +
          this.pf(this.state.t_dist_man_import_sw) +
          this.pf(this.state.t_in_lieu_import_sw),
        import_l_total:
          this.pf(this.state.t_agg_import_l) +
          this.pf(this.state.t_dom_import_l) +
          this.pf(this.state.t_ind_import_l) +
          this.pf(this.state.t_wet_import_l) +
          this.pf(this.state.t_out_import_l) +
          this.pf(this.state.t_veg_import_l) +
          this.pf(this.state.t_other_import_l) +
          this.pf(this.state.t_dist_man_import_l) +
          this.pf(this.state.t_in_lieu_import_l),
        riv_total:
          this.pf(this.state.t_agg_riv) +
          this.pf(this.state.t_dom_riv) +
          this.pf(this.state.t_ind_riv) +
          this.pf(this.state.t_wet_riv) +
          this.pf(this.state.t_out_riv) +
          this.pf(this.state.t_veg_riv) +
          this.pf(this.state.t_other_riv) +
          this.pf(this.state.t_dist_man_riv) +
          this.pf(this.state.t_in_lieu_riv),
        creek_total:
          this.pf(this.state.t_agg_creek) +
          this.pf(this.state.t_dom_creek) +
          this.pf(this.state.t_ind_creek) +
          this.pf(this.state.t_wet_creek) +
          this.pf(this.state.t_out_creek) +
          this.pf(this.state.t_veg_creek) +
          this.pf(this.state.t_other_creek) +
          this.pf(this.state.t_dist_man_creek) +
          this.pf(this.state.t_in_lieu_creek),
        gw_total:
          this.pf(this.state.t_agg_gw) +
          this.pf(this.state.t_dom_gw) +
          this.pf(this.state.t_ind_gw) +
          this.pf(this.state.t_wet_gw) +
          this.pf(this.state.t_out_gw) +
          this.pf(this.state.t_veg_gw) +
          this.pf(this.state.t_other_gw) +
          this.pf(this.state.t_dist_man_gw) +
          this.pf(this.state.t_in_lieu_gw),
        recycled_total:
          this.pf(this.state.t_agg_recycled) +
          this.pf(this.state.t_dom_recycled) +
          this.pf(this.state.t_ind_recycled) +
          this.pf(this.state.t_wet_recycled) +
          this.pf(this.state.t_out_recycled) +
          this.pf(this.state.t_veg_recycled) +
          this.pf(this.state.t_other_recycled) +
          this.pf(this.state.t_dist_man_recycled) +
          this.pf(this.state.t_in_lieu_recycled),
        reused_total:
          this.pf(this.state.t_agg_reused) +
          this.pf(this.state.t_dom_reused) +
          this.pf(this.state.t_ind_reused) +
          this.pf(this.state.t_wet_reused) +
          this.pf(this.state.t_out_reused) +
          this.pf(this.state.t_veg_reused) +
          this.pf(this.state.t_other_reused) +
          this.pf(this.state.t_dist_man_reused) +
          this.pf(this.state.t_in_lieu_reused),
        other_total:
          this.pf(this.state.t_agg_other) +
          this.pf(this.state.t_dom_other) +
          this.pf(this.state.t_ind_other) +
          this.pf(this.state.t_wet_other) +
          this.pf(this.state.t_out_other) +
          this.pf(this.state.t_veg_other) +
          this.pf(this.state.t_other_other) +
          this.pf(this.state.t_dist_man_other) +
          this.pf(this.state.t_in_lieu_other),
        total_total:
          this.pf(this.state.t_agg_total) +
          this.pf(this.state.t_dom_total) +
          this.pf(this.state.t_ind_total) +
          this.pf(this.state.t_wet_total) +
          this.pf(this.state.t_out_total) +
          this.pf(this.state.t_veg_total) +
          this.pf(this.state.t_other_total) +
          this.pf(this.state.t_dist_man_total) +
          this.pf(this.state.t_in_lieu_total),
      });
    }
  }

  //Check for: user && user.role === "GSA Representative" ?

  populateStateFromHistory = () => {
    const { watercur } = this.props;
    if (watercur && watercur.id && !this.state.curdata) {
      this.setState({
        ...this.state,
        ...watercur,
        curdata: true,
      });
    }
  };

  setAuthorized = () => {
    const { user } = this.props;

    let editable = user && user.role === "Administrator";
    if (this.state.editable !== editable) {
      this.setState({ editable: editable });
    }
  };

  pf = (val) => {
    if (val === "-" || val === "" || val === " ") {
      return parseFloat(0);
    } else if (typeof val === "string") {
      return parseFloat(val.replace(/,/g, ""));
    } else {
      return parseFloat(val);
    }
  };

  MAP_OPTIONS = (table) => {
    var t = table.map((row) => ({
      label: row.display_name,
      value: row.value,
    }));

    return t;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    /* Auto-calculating fields after they're updated */
    const {
      t_dom_import_cv,
      t_dom_import_sw,
      t_dom_import_l,
      t_dom_riv,
      t_dom_creek,
      t_dom_gw,
      t_dom_recycled,
      t_dom_reused,
      t_dom_other,
      t_dom_total,

      t_ind_import_cv,
      t_ind_import_sw,
      t_ind_import_l,
      t_ind_riv,
      t_ind_creek,
      t_ind_gw,
      t_ind_recycled,
      t_ind_reused,
      t_ind_other,
      t_ind_total,

      t_agg_import_cv,
      t_agg_import_sw,
      t_agg_import_l,
      t_agg_riv,
      t_agg_creek,
      t_agg_gw,
      t_agg_recycled,
      t_agg_reused,
      t_agg_other,
      t_agg_total,
      t_agg_acc,
      t_agg_calcm,
      t_agg_notes,

      t_wet_import_cv,
      t_wet_import_sw,
      t_wet_import_l,
      t_wet_riv,
      t_wet_creek,
      t_wet_gw,
      t_wet_recycled,
      t_wet_reused,
      t_wet_other,
      t_wet_total,

      t_dist_man_import_cv,
      t_dist_man_import_sw,
      t_dist_man_import_l,
      t_dist_man_riv,
      t_dist_man_creek,
      t_dist_man_gw,
      t_dist_man_recycled,
      t_dist_man_reused,
      t_dist_man_other,
      t_dist_man_total,

      t_in_lieu_import_cv,
      t_in_lieu_import_sw,
      t_in_lieu_import_l,
      t_in_lieu_riv,
      t_in_lieu_creek,
      t_in_lieu_gw,
      t_in_lieu_recycled,
      t_in_lieu_reused,
      t_in_lieu_other,
      t_in_lieu_total,

      t_veg_import_cv,
      t_veg_import_sw,
      t_veg_import_l,
      t_veg_riv,
      t_veg_creek,
      t_veg_gw,
      t_veg_recycled,
      t_veg_reused,
      t_veg_other,
      t_veg_total,

      t_out_import_cv,
      t_out_import_sw,
      t_out_import_l,
      t_out_riv,
      t_out_creek,
      t_out_gw,
      t_out_recycled,
      t_out_reused,
      t_out_other,
      t_out_total,

      t_other_import_cv,
      t_other_import_sw,
      t_other_import_l,
      t_other_riv,
      t_other_creek,
      t_other_gw,
      t_other_recycled,
      t_other_reused,
      t_other_other,
      t_other_total,

      t_total_total,

      dom_total,
      ind_total,
      agg_total,
      wet_total,
      dist_man_total,
      in_lieu_total,
      veg_total,
      out_total,
      other_total,
    } = this.state;

    // Totals
    if (
      prevState.t_agg_import_cv !== t_agg_import_cv ||
      prevState.t_dom_import_cv !== t_dom_import_cv ||
      prevState.t_ind_import_cv !== t_ind_import_cv ||
      prevState.t_wet_import_cv !== t_wet_import_cv ||
      prevState.t_out_import_cv !== t_out_import_cv ||
      prevState.t_veg_import_cv !== t_veg_import_cv ||
      prevState.t_other_import_cv !== t_other_import_cv ||
      prevState.t_dist_man_import_cv !== t_dist_man_import_cv ||
      prevState.t_in_lieu_import_cv !== t_in_lieu_import_cv
    ) {
      this.setState({
        import_cv_total:
          this.pf(t_agg_import_cv) +
          this.pf(t_dom_import_cv) +
          this.pf(t_ind_import_cv) +
          this.pf(t_wet_import_cv) +
          this.pf(t_out_import_cv) +
          this.pf(t_veg_import_cv) +
          this.pf(t_other_import_cv) +
          this.pf(t_dist_man_import_cv) +
          this.pf(t_in_lieu_import_cv),
      });
    }
    if (
      prevState.t_agg_import_sw !== t_agg_import_sw ||
      prevState.t_dom_import_sw !== t_dom_import_sw ||
      prevState.t_ind_import_sw !== t_ind_import_sw ||
      prevState.t_wet_import_sw !== t_wet_import_sw ||
      prevState.t_out_import_sw !== t_out_import_sw ||
      prevState.t_veg_import_sw !== t_veg_import_sw ||
      prevState.t_other_import_sw !== t_other_import_sw ||
      prevState.t_dist_man_import_sw !== t_dist_man_import_sw ||
      prevState.t_in_lieu_import_sw !== t_in_lieu_import_sw
    ) {
      this.setState({
        import_sw_total:
          this.pf(t_agg_import_sw) +
          this.pf(t_dom_import_sw) +
          this.pf(t_ind_import_sw) +
          this.pf(t_wet_import_sw) +
          this.pf(t_out_import_sw) +
          this.pf(t_veg_import_sw) +
          this.pf(t_other_import_sw) +
          this.pf(t_dist_man_import_sw) +
          this.pf(t_in_lieu_import_sw),
      });
    }
    if (
      prevState.t_agg_import_l !== t_agg_import_l ||
      prevState.t_dom_import_l !== t_dom_import_l ||
      prevState.t_ind_import_l !== t_ind_import_l ||
      prevState.t_wet_import_l !== t_wet_import_l ||
      prevState.t_out_import_l !== t_out_import_l ||
      prevState.t_veg_import_l !== t_veg_import_l ||
      prevState.t_other_import_l !== t_other_import_l ||
      prevState.t_dist_man_import_l !== t_dist_man_import_l ||
      prevState.t_in_lieu_import_l !== t_in_lieu_import_l
    ) {
      this.setState({
        import_l_total:
          this.pf(t_agg_import_l) +
          this.pf(t_dom_import_l) +
          this.pf(t_ind_import_l) +
          this.pf(t_wet_import_l) +
          this.pf(t_out_import_l) +
          this.pf(t_veg_import_l) +
          this.pf(t_other_import_l) +
          this.pf(t_dist_man_import_l) +
          this.pf(t_in_lieu_import_l),
      });
    }
    if (
      prevState.t_agg_riv !== t_agg_riv ||
      prevState.t_dom_riv !== t_dom_riv ||
      prevState.t_ind_riv !== t_ind_riv ||
      prevState.t_wet_riv !== t_wet_riv ||
      prevState.t_out_riv !== t_out_riv ||
      prevState.t_veg_riv !== t_veg_riv ||
      prevState.t_other_riv !== t_other_riv ||
      prevState.t_dist_man_riv !== t_dist_man_riv ||
      prevState.t_in_lieu_riv !== t_in_lieu_riv
    ) {
      this.setState({
        riv_total:
          this.pf(t_agg_riv) +
          this.pf(t_dom_riv) +
          this.pf(t_ind_riv) +
          this.pf(t_wet_riv) +
          this.pf(t_out_riv) +
          this.pf(t_veg_riv) +
          this.pf(t_other_riv) +
          this.pf(t_dist_man_riv) +
          this.pf(t_in_lieu_riv),
      });
    }
    if (
      prevState.t_agg_creek !== t_agg_creek ||
      prevState.t_dom_creek !== t_dom_creek ||
      prevState.t_ind_creek !== t_ind_creek ||
      prevState.t_wet_creek !== t_wet_creek ||
      prevState.t_out_creek !== t_out_creek ||
      prevState.t_veg_creek !== t_veg_creek ||
      prevState.t_other_creek !== t_other_creek ||
      prevState.t_dist_man_creek !== t_dist_man_creek ||
      prevState.t_in_lieu_creek !== t_in_lieu_creek
    ) {
      this.setState({
        creek_total:
          this.pf(t_agg_creek) +
          this.pf(t_dom_creek) +
          this.pf(t_ind_creek) +
          this.pf(t_wet_creek) +
          this.pf(t_out_creek) +
          this.pf(t_veg_creek) +
          this.pf(t_other_creek) +
          this.pf(t_dist_man_creek) +
          this.pf(t_in_lieu_creek),
      });
    }
    if (
      prevState.t_agg_gw !== t_agg_gw ||
      prevState.t_dom_gw !== t_dom_gw ||
      prevState.t_ind_gw !== t_ind_gw ||
      prevState.t_wet_gw !== t_wet_gw ||
      prevState.t_out_gw !== t_out_gw ||
      prevState.t_veg_gw !== t_veg_gw ||
      prevState.t_other_gw !== t_other_gw ||
      prevState.t_dist_man_gw !== t_dist_man_gw ||
      prevState.t_in_lieu_gw !== t_in_lieu_gw
    ) {
      this.setState({
        gw_total:
          this.pf(t_agg_gw) +
          this.pf(t_dom_gw) +
          this.pf(t_ind_gw) +
          this.pf(t_wet_gw) +
          this.pf(t_out_gw) +
          this.pf(t_veg_gw) +
          this.pf(t_other_gw) +
          this.pf(t_dist_man_gw) +
          this.pf(t_in_lieu_gw),
      });
    }
    if (
      prevState.t_agg_recycled !== t_agg_recycled ||
      prevState.t_dom_recycled !== t_dom_recycled ||
      prevState.t_ind_recycled !== t_ind_recycled ||
      prevState.t_wet_recycled !== t_wet_recycled ||
      prevState.t_out_recycled !== t_out_recycled ||
      prevState.t_veg_recycled !== t_veg_recycled ||
      prevState.t_other_recycled !== t_other_recycled ||
      prevState.t_dist_man_recycled !== t_dist_man_recycled ||
      prevState.t_in_lieu_recycled !== t_in_lieu_recycled
    ) {
      this.setState({
        recycled_total:
          this.pf(t_agg_recycled) +
          this.pf(t_dom_recycled) +
          this.pf(t_ind_recycled) +
          this.pf(t_wet_recycled) +
          this.pf(t_out_recycled) +
          this.pf(t_veg_recycled) +
          this.pf(t_other_recycled) +
          this.pf(t_dist_man_recycled) +
          this.pf(t_in_lieu_recycled),
      });
    }
    if (
      prevState.t_agg_reused !== t_agg_reused ||
      prevState.t_dom_reused !== t_dom_reused ||
      prevState.t_ind_reused !== t_ind_reused ||
      prevState.t_wet_reused !== t_wet_reused ||
      prevState.t_out_reused !== t_out_reused ||
      prevState.t_veg_reused !== t_veg_reused ||
      prevState.t_other_reused !== t_other_reused ||
      prevState.t_dist_man_reused !== t_dist_man_reused ||
      prevState.t_in_lieu_reused !== t_in_lieu_reused
    ) {
      this.setState({
        reused_total:
          this.pf(t_agg_reused) +
          this.pf(t_dom_reused) +
          this.pf(t_ind_reused) +
          this.pf(t_wet_reused) +
          this.pf(t_out_reused) +
          this.pf(t_veg_reused) +
          this.pf(t_other_reused) +
          this.pf(t_dist_man_reused) +
          this.pf(t_in_lieu_reused),
      });
    }
    if (
      prevState.t_agg_other !== t_agg_other ||
      prevState.t_dom_other !== t_dom_other ||
      prevState.t_ind_other !== t_ind_other ||
      prevState.t_wet_other !== t_wet_other ||
      prevState.t_out_other !== t_out_other ||
      prevState.t_veg_other !== t_veg_other ||
      prevState.t_other_other !== t_other_other ||
      prevState.t_dist_man_other !== t_dist_man_other ||
      prevState.t_in_lieu_other !== t_in_lieu_other
    ) {
      this.setState({
        other_total:
          this.pf(t_agg_other) +
          this.pf(t_dom_other) +
          this.pf(t_ind_other) +
          this.pf(t_wet_other) +
          this.pf(t_out_other) +
          this.pf(t_veg_other) +
          this.pf(t_other_other) +
          this.pf(t_dist_man_other) +
          this.pf(t_in_lieu_other),
      });
    }
    if (
      prevState.t_agg_total !== t_agg_total ||
      prevState.t_dom_total !== t_dom_total ||
      prevState.t_ind_total !== t_ind_total ||
      prevState.t_wet_total !== t_wet_total ||
      prevState.t_out_total !== t_out_total ||
      prevState.t_veg_total !== t_veg_total ||
      prevState.t_other_total !== t_other_total ||
      prevState.t_dist_man_total !== t_dist_man_total ||
      prevState.t_in_lieu_total !== t_in_lieu_total
    ) {
      this.setState({
        total_total:
          this.pf(t_agg_total) +
          this.pf(t_dom_total) +
          this.pf(t_ind_total) +
          this.pf(t_wet_total) +
          this.pf(t_out_total) +
          this.pf(t_veg_total) +
          this.pf(t_other_total) +
          this.pf(t_dist_man_total) +
          this.pf(t_in_lieu_total),
      });
    }
  }

  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
              name: other.name,
            },
          });
        }}
        thousandSeparator
      />
    );
  };

  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };

  handleChangeNumeric = (evt) => {
    const re = /^-?[-0-9\b]+$/;
    const val = evt.target.value.replace(/,/g, "");
    if (val === "" || re.test(val)) {
      this.setState({
        [evt.target.name]: val,
      });
    }
  };

  handleSave = (evt) => {
    evt.preventDefault();
    const { ormTotalWaterUseCreate, ormTotalWaterUseUpdate } = this.props;
    const { user, watercur, history } = this.props;
    const { curdata } = this.state;

    this.setState({
      submitted: true,
      water_year_id: watercur.wy.id,
      gsp_id: user.gsp.id,
    });

    const stObj = {
      ...this.state,
      submitted: true,
      water_year_id: watercur.wy.id,
      gsp_id: user.gsp.id,
    };

    if (!curdata) {
      ormTotalWaterUseCreate(stObj);
    } else {
      ormTotalWaterUseUpdate(stObj);
    }

    history.push("/totalwateruse");
  };

  // submitForm = (evt) => {
  //   evt.preventDefault();
  //   const { ormTotalWaterUseUpdate, history } = this.props;

  //   this.setState({
  //     submitted: true,
  //   });

  //   const stObj = {
  //     ...this.state,
  //     submitted: true,
  //   };

  //   ormTotalWaterUseUpdate(stObj);

  //   history.push("/totalwateruse");
  // };

  render() {
    this.setAuthorized();

    this.populateStateFromHistory();

    const {
      submitted,
      import_cv_total,
      import_sw_total,
      import_l_total,
      riv_total,
      creek_total,
      gw_total,
      recycled_total,
      reused_total,
      other_total,
      total_total,
      helpOpen,
      siteChoices,
      editable,
    } = this.state;
    const { classes, user, wateryr, watercur, waterhist, optionsReq } =
      this.props;

    //OPTIONS comes back as a promise
    if (optionsReq && !siteChoices) {
      optionsReq.then((data) => {
        if (!this.state.siteChoices) {
          this.setState({
            siteChoices: data,
          });
        }
      });
    }

    return (
      <AppContainer authenticated>
        <div className={classes.container}>
          <form>
            {watercur && watercur.wy ? (
              <>
                <BreadcrumbNav
                  level1="Total Water Use Data"
                  level1link="/totalwateruse"
                  level2="Total Water Use Details"
                  level2link={
                    "/reporting/totaluse/" +
                    this.props.match.params["gsp"] +
                    "/" +
                    this.props.match.params["id"]
                  }
                />
                <br />
              </>
            ) : (
              <>
                <BreadcrumbNav
                  level1="Associated GSP's"
                  level1link="/reporting"
                  level2="Report Total Water Use Data"
                  level2link={
                    "/reporting/totaluse/" + this.props.match.params["gsp"]
                  }
                />
                <br />
              </>
            )}

            <Grid container spacing={24}>
              <Grid item xs={12}>
                {watercur && watercur.wy ? (
                  <>
                    <span className={classes.lgHeader}>
                      Water Year {watercur.wy.water_year}:{" "}
                      {watercur.wy.water_year_start
                        ? new Date(
                            watercur.wy.water_year_start + " "
                          ).toLocaleDateString("en-US")
                        : "Start Date Not Specified"}{" "}
                      -{" "}
                      {watercur.wy.water_year_start
                        ? new Date(
                            watercur.wy.water_year_end + " "
                          ).toLocaleDateString("en-US")
                        : "End Date Not Specified"}
                    </span>
                  </>
                ) : (
                  <>
                    <hr className={classes.hrclass} />
                    <span className={classes.lgHeader}>
                      Current Water Year {wateryr ? wateryr.water_year : ""}:{" "}
                      {wateryr
                        ? new Date(
                            wateryr.water_year_start + " "
                          ).toLocaleDateString("en-US")
                        : ""}{" "}
                      -{" "}
                      {wateryr
                        ? new Date(
                            wateryr.water_year_end + " "
                          ).toLocaleDateString("en-US")
                        : ""}
                    </span>
                  </>
                )}
              </Grid>
              <hr />
              <Grid item xs={12}>
                <hr className={classes.hrclass} />
                <h2 className={classes.mdHeader}>
                  Total Water Use Data
                  <HelpLabel
                    open={helpOpen}
                    question="Total Water Use"
                    showHelp={true}
                    inputLabel={true}
                    helpText={
                      <>
                        Volume of water used by each water use sector. This
                        volume may be equal to or less than the volume of
                        groundwater or surface water supplies delivered. A water
                        use volume less than the volume of supplies delivered
                        may indicate a loss of water during delivery and/or use
                        of precipitation. Estimated water use shall consider all
                        water sources available, including precipitation,
                        surface water seepage, and passive groundwater use.
                      </>
                    }
                  />
                </h2>

                <Table>
                  <TableBody>
                    {/* table header */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Water Source Type
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Imported - Central Valley Project{" "}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Imported - State Water Project{" "}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Imported - Local Imported Supplies{" "}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Local Surface Water
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Ground Water
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Recycled Water
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Reused Water
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Other
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Other Source Description
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Total
                      </TableCell>
                      {/* <TableCell xs={2} className={classes.tc}>
                        Source Notes
                      </TableCell> */}
                      <TableCell xs={3} className={classes.tc}>
                        Explain methods used to calculate total water use
                      </TableCell>
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        Calculation Accuracy
                      </TableCell> */}
                    </TableRow>

                    {/* Urban/Domestic */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Urban/Domestic:
                        <HelpLabel
                          open={helpOpen}
                          question="Urban/Domestic Water Use"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              Municipal, domestic, commercial and/or
                              institutional water use, either a metered delivery
                              or an estimate of water delivered. Note that
                              industrial water use is reported as a separate
                              category.
                            </>
                          }
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_import_cv"
                          disabled={!editable}
                          value={this.state.t_dom_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_import_sw"
                          disabled={!editable}
                          value={this.state.t_dom_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_import_l"
                          disabled={!editable}
                          value={this.state.t_dom_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_riv"
                          disabled={!editable}
                          value={this.state.t_dom_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_gw"
                          disabled={!editable}
                          value={this.state.t_dom_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_recycled"
                          disabled={!editable}
                          value={this.state.t_dom_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_reused"
                          disabled={!editable}
                          value={this.state.t_dom_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_other"
                          disabled={!editable}
                          value={this.state.t_dom_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_dom_other_desc"
                          disabled={!editable}
                          value={this.state.t_dom_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dom_total"
                          disabled={!editable}
                          value={this.state.t_dom_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_dom_notes"
                          disabled={!editable}
                          value={this.state.t_dom_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_dom_calcm"
                            value={this.state.t_dom_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_dom_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_dom_acc"
                            value={this.state.t_dom_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_dom_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* Industrial */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Industrial:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_import_cv"
                          disabled={!editable}
                          value={this.state.t_ind_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_import_sw"
                          disabled={!editable}
                          value={this.state.t_ind_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_import_l"
                          disabled={!editable}
                          value={this.state.t_ind_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_riv"
                          disabled={!editable}
                          value={this.state.t_ind_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_gw"
                          disabled={!editable}
                          value={this.state.t_ind_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_recycled"
                          disabled={!editable}
                          value={this.state.t_ind_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_reused"
                          disabled={!editable}
                          value={this.state.t_ind_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_other"
                          disabled={!editable}
                          value={this.state.t_ind_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_ind_other_desc"
                          disabled={!editable}
                          value={this.state.t_ind_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_ind_total"
                          disabled={!editable}
                          value={this.state.t_ind_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_ind_notes"
                          disabled={!editable}
                          value={this.state.t_ind_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_ind_calcm"
                            value={this.state.t_ind_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_ind_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_ind_acc"
                            value={this.state.t_ind_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_ind_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* Agricultural */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Agricultural:
                        <HelpLabel
                          open={helpOpen}
                          question="Agricultural Water Use"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              Surface water delivered and metered at a lateral
                              turnout and used for agricultural purposes. (Note
                              that this should be the same volume as is reported
                              by each contract holder to a state or federal
                              agency each year.) If metered data are not
                              available, estimated water used by agricultural
                              should be provided.
                            </>
                          }
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_import_cv"
                          disabled={!editable}
                          value={this.state.t_agg_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_import_sw"
                          disabled={!editable}
                          value={this.state.t_agg_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_import_l"
                          disabled={!editable}
                          value={this.state.t_agg_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_riv"
                          disabled={!editable}
                          value={this.state.t_agg_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_creek"
                          disabled={submitted || !authorized}
                          value={this.state.t_agg_creek}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell> */}
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_gw"
                          disabled={!editable}
                          value={this.state.t_agg_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_recycled"
                          disabled={!editable}
                          value={this.state.t_agg_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_reused"
                          disabled={!editable}
                          value={this.state.t_agg_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_other"
                          disabled={!editable}
                          value={this.state.t_agg_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_agg_other_desc"
                          disabled={!editable}
                          value={this.state.t_agg_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_agg_total"
                          disabled={!editable}
                          value={this.state.t_agg_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_agg_notes"
                          disabled={!editable}
                          value={this.state.t_agg_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_agg_calcm"
                            value={this.state.t_agg_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_agg_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_agg_acc"
                            value={this.state.t_agg_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_agg_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* Managed Wetlands */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Managed Wetlands:
                        <HelpLabel
                          open={helpOpen}
                          question="Managed Wetlands Water Use"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              Water delivered and metered at a lateral turnout
                              or diversion and used for environmental purposes
                              on managed wetland areas such as wildlife refuges.
                              If metered data are not available, estimated water
                              used by the managed wetlands should be provided.
                            </>
                          }
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_import_cv"
                          disabled={!editable}
                          value={this.state.t_wet_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_import_sw"
                          disabled={!editable}
                          value={this.state.t_wet_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_import_l"
                          disabled={!editable}
                          value={this.state.t_wet_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_riv"
                          disabled={!editable}
                          value={this.state.t_wet_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_creek"
                          disabled={!editable}
                          value={this.state.t_wet_creek}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell> */}
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_gw"
                          disabled={!editable}
                          value={this.state.t_wet_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_recycled"
                          disabled={!editable}
                          value={this.state.t_wet_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_reused"
                          disabled={!editable}
                          value={this.state.t_wet_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_other"
                          disabled={!editable}
                          value={this.state.t_wet_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_wet_other_desc"
                          disabled={!editable}
                          value={this.state.t_wet_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_wet_total"
                          disabled={!editable}
                          value={this.state.t_wet_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_wet_notes"
                          disabled={!editable}
                          value={this.state.t_wet_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_wet_calcm"
                            value={this.state.t_wet_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_wet_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_wet_acc"
                            value={this.state.t_wet_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_wet_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* District Managed Recharge */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        District Managed Recharge:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_import_cv"
                          disabled={!editable}
                          value={this.state.t_dist_man_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_import_sw"
                          disabled={!editable}
                          value={this.state.t_dist_man_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_import_l"
                          disabled={!editable}
                          value={this.state.t_dist_man_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_riv"
                          disabled={!editable}
                          value={this.state.t_dist_man_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_gw"
                          disabled={!editable}
                          value={this.state.t_dist_man_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_recycled"
                          disabled={!editable}
                          value={this.state.t_dist_man_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_reused"
                          disabled={!editable}
                          value={this.state.t_dist_man_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_other"
                          disabled={!editable}
                          value={this.state.t_dist_man_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_dist_man_other_desc"
                          disabled={!editable}
                          value={this.state.t_dist_man_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_dist_man_total"
                          disabled={!editable}
                          value={this.state.t_dist_man_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_dist_man_notes"
                          disabled={!editable}
                          value={this.state.t_dist_man_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_dist_man_calcm"
                            value={this.state.t_dist_man_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_dist_man_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_dist_man_acc"
                            value={this.state.t_dist_man_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_dist_man_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* Native Vegetation */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Native Vegetation:
                        <HelpLabel
                          open={helpOpen}
                          question="Native Vegetation Water Use"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              Estimated water use (uptaken) by native vegetation
                              regardless of the source.
                            </>
                          }
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_import_cv"
                          disabled={!editable}
                          value={this.state.t_veg_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_import_sw"
                          disabled={!editable}
                          value={this.state.t_veg_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_import_l"
                          disabled={!editable}
                          value={this.state.t_veg_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_riv"
                          disabled={!editable}
                          value={this.state.t_veg_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_gw"
                          disabled={!editable}
                          value={this.state.t_veg_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_recycled"
                          disabled={!editable}
                          value={this.state.t_veg_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_reused"
                          disabled={!editable}
                          value={this.state.t_veg_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_other"
                          disabled={!editable}
                          value={this.state.t_veg_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_veg_other_desc"
                          disabled={!editable}
                          value={this.state.t_veg_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_veg_total"
                          disabled={!editable}
                          value={this.state.t_veg_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_veg_notes"
                          disabled={!editable}
                          value={this.state.t_veg_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_veg_calcm"
                            value={this.state.t_veg_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_veg_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_veg_acc"
                            value={this.state.t_veg_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_veg_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* Other */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Other:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_import_cv"
                          disabled={!editable}
                          value={this.state.t_other_import_cv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_import_sw"
                          disabled={!editable}
                          value={this.state.t_other_import_sw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_import_l"
                          disabled={!editable}
                          value={this.state.t_other_import_l}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_riv"
                          disabled={!editable}
                          value={this.state.t_other_riv}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_gw"
                          disabled={!editable}
                          value={this.state.t_other_gw}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_recycled"
                          disabled={!editable}
                          value={this.state.t_other_recycled}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_reused"
                          disabled={!editable}
                          value={this.state.t_other_reused}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_other"
                          disabled={!editable}
                          value={this.state.t_other_other}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_other_other_desc"
                          disabled={!editable}
                          value={this.state.t_other_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_other_total"
                          disabled={!editable}
                          value={this.state.t_other_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="t_other_notes"
                          disabled={!editable}
                          value={this.state.t_other_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="t_other_calcm"
                            value={this.state.t_other_calcm}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_other_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="t_other_acc"
                            value={this.state.t_other_acc}
                            onChange={this.handleChange}
                            disabled={!editable}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.t_other_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell> */}
                    </TableRow>

                    {/* Other Use Description */}
                    <TableRow>
                      <TableCell xs={3} className={classes.tc}>
                        Other Use Description:
                      </TableCell>
                      <TableCell
                        xs={3}
                        className={classes.tcNumber}
                        colSpan={6}
                      >
                        <TextField
                          name="t_other_use_desc"
                          disabled={!editable}
                          value={this.state.t_other_use_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={2} className={classes.tc}></TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={2} className={classes.tc}></TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={2} className={classes.tc}></TableCell>
                      {/* <TableCell xs={3} className={classes.tc}></TableCell> */}
                      {/* <TableCell xs={3} className={classes.tc}></TableCell> */}
                    </TableRow>

                    {/* Total */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Total:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="import_cv_total"
                          value={import_cv_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="import_sw_total"
                          value={import_sw_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="import_l_total"
                          value={import_l_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="riv_total"
                          value={riv_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="gw_total"
                          value={gw_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="recycled_total"
                          value={recycled_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tcNumber}>
                        <TextField
                          name="reused_total"
                          value={reused_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="other_total"
                          value={other_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        xs={3}
                        className={classes.tcNumber}
                      ></TableCell>
                      <TableCell xs={3} className={classes.tcNumber}>
                        <TextField
                          name="total_total"
                          value={total_total}
                          disabled={true}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      {/* 
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="t_total_total"
                          disabled={submitted || !authorized}
                          value={this.state.t_total_total}
                          onChange={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell> */}

                      {/* <TableCell xs={3} className={classes.tcNumber}></TableCell> */}
                      <TableCell
                        xs={3}
                        className={classes.tcNumber}
                      ></TableCell>
                      {/* <TableCell xs={3} className={classes.tcNumber}></TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              {editable && (
                <>
                  <Grid item xs={false} sm={8} md={9} lg={10}></Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="button"
                      label="Save"
                      onClick={this.handleSave}
                      color="primary"
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </div>
      </AppContainer>
    );
  }
}
ReportingTotalWaterUse = connect(
  (state, ownProps) => ({
    optionsReq: getOptions(state, ownProps),
    wateryr: getWaterYear(state, ownProps),
    user: getUserData(state, ownProps),
    watercur: currentTotalWaterUse(state, ownProps),
    waterhist: historicTotalWaterUse(state, ownProps),
    authState: state.auth,
  }),
  {
    ...TotalWaterUse.actions,
    ...authActions,
    ...navActions,
  }
)(ReportingTotalWaterUse);
export default withStyles(styles)(withRouter(ReportingTotalWaterUse));
