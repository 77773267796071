import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom"; //, Link
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
//import TextField from '@material-ui/core/TextField';
import { BlueButton } from "../common/Button";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import Icon from "@mdi/react";
import { mdiUndo, mdiEye, mdiLock } from "@mdi/js";

const styles = (theme) => ({});

class AdminGSPReportingStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gsp_statuses: [],
    };
  }
  addGSPForm = () => {
    console.log("adding GSP");
  };
  viewSubmission = (GSP_id) => {
    console.log("Editing GSP: " + GSP_id);
  };
  toggleLock = (GSP_id) => {
    console.log("Returning without deleting GSP");
  };

  render() {
    const { gsp_statuses } = this.state; // GSPs need to go in through the Redux model
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={3}>
            <BlueButton
              label="Return to Administration"
              theAction="/administration"
              theIcon={<Icon path={mdiUndo} size={1} color="white"></Icon>}
            ></BlueButton>
          </Grid>
          <Grid item xs={9}></Grid>
          <Grid item xs={12}>
            Current Water Year: October 1, 2018 - September 30, 2019
          </Grid>

          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Actions</TableCell>
                  <TableCell>GSP Name</TableCell>
                  <TableCell>
                    Groundwater <br /> Storage
                  </TableCell>
                  <TableCell>
                    Groundwater <br /> Use
                  </TableCell>
                  <TableCell>
                    Surface Water <br /> Use
                  </TableCell>
                  <TableCell>
                    Water Level <br /> Data
                  </TableCell>
                  <TableCell>
                    Interconnected <br /> SW Monitoring <br />
                    Data
                  </TableCell>
                  <TableCell>
                    Water Quality <br />
                    Data
                  </TableCell>
                  <TableCell>
                    Subsidence <br /> Data
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {gsp_statuses.map((item) => (
                  <TableRow>
                    <TableCell>
                      <Button
                        key={item.id + "-view"}
                        onClick={this.viewSubmission}
                      >
                        <Icon path={mdiEye} size={1} color="Blue"></Icon>
                      </Button>
                      <Button
                        key={item.id + "-unlock"}
                        onClick={this.toggleLock(item.id)}
                      >
                        <Icon path={mdiLock} size={1} color="Blue"></Icon>
                      </Button>
                    </TableCell>
                    <TableCell>{item.gsp_name}</TableCell>
                    <TableCell>{item.gw_storage}</TableCell>
                    <TableCell>{item.gw_use}</TableCell>
                    <TableCell>{item.sw_use}</TableCell>
                    <TableCell>{item.wl_data}</TableCell>
                    <TableCell>{item.swgw_int_data}</TableCell>
                    <TableCell>{item.wq_data}</TableCell>
                    <TableCell>{item.subs_data}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </AppContainer>
    );
  }
}
AdminGSPReportingStatus = connect(
  (state) => ({ authState: state.auth }),
  {}
)(AdminGSPReportingStatus);
export default withStyles(styles)(withRouter(AdminGSPReportingStatus));
