import React, { Component } from "react";
import PropTypes from 'prop-types';

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import FlexibleForm from "../common/flexibleForm";

import { withStyles } from "@material-ui/core/styles";

const styles = ({
    ts_hidden: {
        display: 'none'
    },
    ts_shown: {
        display: 'table-cell'
    }
});

class TimeSeriesEdit extends Component {
    render() {
        const { onClose, open, row, fieldconf, onSubmit } = this.props; // classes, selectedValue, wellTimes
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="simple-dialog-title">
                <DialogTitle>Edit</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FlexibleForm
                            saveFlexible={onSubmit}
                            fieldconf={fieldconf}
                            fields={row} 
                            isEdit={true}
                            hasMap={false} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        )
    }
}

TimeSeriesEdit.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    selectedValue: PropTypes.string,
};

export default withStyles(styles)(TimeSeriesEdit);