import React, { Component } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import ListIcon from "@material-ui/icons/List";
import PollIcon from "@material-ui/icons/Poll";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import NotReady from "../common/NotReady";
import WellSiteDetails from "../../api/wellsiteDetailsHOC";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },
  lastBorder: {
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  lastBorder3: {
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  expandedMargin: {
    marginTop: "12px",
    minHeight: "48px !important",
    maxHeight: 48,
    backgroundColor: "#eee",
  },
  borderContainer: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
    borderRight: "2px solid " + theme.palette.primary.light,
    borderLeft: "2px solid " + theme.palette.primary.light,
  },
  borderContainer2: {
    border: "1px solid rgb(221, 221, 221)",
  },
  buttonSelected: {
    border: "3px solid " + theme.palette.primary.main,
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
  iconNotSelected: {
    color: "#666",
  },
  divideButton: {
    float: "right",
    // marginBottom: 16,
    // width: "100%",
    // marginTop: 16,
  },
  divideContainer: {
    backgroundColor: "#ddd",
    textAlign: "center",
    margin: "0 -4px",
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  marginLeft: {
    marginLeft: -16,
  },
  chartTitle: {
    backgroundColor: "rgb(221, 221, 221)",
    textAlign: "center",
    padding: "8px 4px !important",
  },
  marginTop: {
    marginTop: 8,
    marginRight: 8,
  },
});

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <span>{`GSA: ${payload[0].payload.gsa}`}</span>
        <br />
        <span>{`Storage Unit: ${payload[0].payload.storage_unit}`}</span>
        <br />
        <span>{`Date: ${payload[0].payload.display_date}`}</span>
        <br />
        <span>{`Pumping Volume (ac-ft): ${parseFloat(
          payload[0].payload.measurement_af
        )}`}</span>
      </div>
    );
  }

  return null;
};

const columnData = [
  { id: "measurement_date", numeric: false, label: "Date", allowSort: true },
  {
    id: "measurement_af",
    numeric: true,
    label: "Measurement",
    allowSort: true,
  },
  { id: "method", numeric: false, label: "Method", allowSort: true },
  { id: "notes", numeric: false, label: "Notes", allowSort: true },
];

class WellPumpingDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      measurements: [],
      buttonState: "chart",
      order: "desc",
      orderBy: "measurement_date",
      page: 0,
      rowsPerPage: 10,
    };
  }

  componentDidUpdate() {
    const { measurements } = this.state;
    this.formKey = window.performance.now();
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.state.measurements.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  formatDate(dateString) {
    if (!dateString) {
      return null;
    }
    const d = new Date(dateString + " 12:00:00");
    return d.toLocaleDateString();
  }

  getMinDate(arr) {
    let val = new Date(
      arr.reduce(
        (min, p) =>
          new Date(p.measurement_date + " 12:00:00") < min
            ? new Date(p.measurement_date + " 12:00:00")
            : min,
        new Date(arr[0].measurement_date + " 12:00:00")
      )
    );

    return val;
  }

  getMaxDate(arr) {
    let val = new Date(
      arr.reduce(
        (max, p) =>
          new Date(p.measurement_date + " 12:00:00") > max
            ? new Date(p.measurement_date + " 12:00:00")
            : max,
        new Date(arr[0].measurement_date + " 12:00:00")
      )
    );

    return val;
  }

  getMinMaxVal(arr, site) {
    let min = 1000000000,
      max = 0;

    for (let i = 1, len = arr.length; i < len; i++) {
      let v = arr[i]["measurement_af"];
      min = v < min ? v : min;
      max = v > max ? v : max;
    }

    if (min && min != 0) {
      min = this.round(min - 5, 0);
    }

    max = this.round(max + 5, 0);

    return { min, max };
  }

  round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  };

  handleAPIChange(data) {
    this.setState({ site: data.site, measurements: data.site.measurements });
  }

  render() {
    const { classes } = this.props;
    const {
      site,
      measurements,
      buttonState,
      page,
      rowsPerPage,
      order,
      orderBy,
    } = this.state;

    let siteId = parseInt(this.props.match.params["id"]);

    let level1 = "Well Pumping";
    let level1link = "/wellpumpinglist";
    let level2link = "/wellpumpingdetail/" + siteId;

    let measurements_to_chart =
      measurements && measurements.length > 0
        ? measurements.filter((s) => s.measurement_af != null)
        : [];

    measurements_to_chart.forEach(function (m) {
      if (!m.chart_date) {
        var d;
        if (m.measurement_time && m.meaurement_time !== "")
          d = new Date(m.measurement_date + " " + m.measurement_time);
        else d = new Date(m.measurement_date + " 12:00:00");
        m.chart_date = d.getTime();
      }
      if (!m.display_date) {
        var d;
        if (m.measurement_time && m.meaurement_time !== "")
          d = new Date(m.measurement_date + " " + m.measurement_time);
        else d = new Date(m.measurement_date + " 12:00:00");
        m.display_date = d.toLocaleDateString();
      }
    });

    measurements_to_chart.forEach((m) => {
      m.measurement_af = this.roundToTwo(m.measurement_af);
    });

    measurements_to_chart.sort((m, n) => m.chart_date - n.chart_date);

    if (site === undefined) {
      return (
        <AppContainer authenticated>
          <WellSiteDetails
            apitarget={"wellpumpingmeasurements"}
            siteid={siteId}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1={level1}
                level1link={level1link}
                level2={"DMS ID: " + site.dms_site_id}
                level2link={level2link}
              />
            </Grid>

            <Grid
              item
              xs={12}
              style={{ marginBottom: "0px", paddingBottom: "0px" }}
            >
              <Typography variant="h5">
                Well Pumping
                <Button
                  title="Table"
                  onClick={() => this.setState({ buttonState: "table" })}
                  className={classNames(
                    classes.divideButton,
                    buttonState === "table"
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                >
                  <ListIcon
                    className={
                      buttonState === "table"
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
                <Button
                  title="Chart"
                  onClick={() => this.setState({ buttonState: "chart" })}
                  className={classNames(
                    classes.divideButton,
                    buttonState === "chart"
                      ? classes.buttonSelected
                      : classes.buttonNotSelected
                  )}
                >
                  <PollIcon
                    className={
                      buttonState === "chart"
                        ? classes.iconSelected
                        : classes.iconNotSelected
                    }
                  />
                </Button>
              </Typography>
            </Grid>

            {buttonState === "table" && (
              <Grid item xs={12}>
                <Paper className={classes.root}>
                  <Table className={classes.table}>
                    <EnhancedTableHead
                      columnData={columnData}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={this.handleRequestSort}
                    />
                    <TableBody>
                      {measurements
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item.id}>
                            <TableCell>
                              {this.formatDate(item.measurement_date)}
                            </TableCell>
                            <TableCell>{item.measurement_af}</TableCell>
                            <TableCell>{item.meathod}</TableCell>
                            <TableCell>{item.notes}</TableCell>
                          </TableRow>
                        ))}
                      {measurements.length < 1 && (
                        <TableRow>
                          <TableCell
                            colSpan={7}
                            className={classes.centerAlign}
                          >
                            No Measurements Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                    {measurements.length > 10 && (
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            colSpan={7}
                            count={measurements.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActionsWrapped}
                          />
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                </Paper>
              </Grid>
            )}

            {buttonState === "chart" && (
              <>
                {measurements_to_chart.length === 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h5" className={classes.centerAlign}>
                      No Measurements Found
                    </Typography>
                  </Grid>
                )}
                {measurements_to_chart.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <ResponsiveContainer
                        width="100%"
                        height={680}
                        ref="chartContainer"
                      >
                        <LineChart data={measurements_to_chart}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="chart_date"
                            name="Measurement Date"
                            domain={[
                              this.getMinDate(measurements_to_chart),
                              this.getMaxDate(measurements_to_chart),
                            ]}
                            type="number"
                            tickFormatter={(unixTime) =>
                              moment(unixTime).format("MM/DD/YYYY")
                            }
                          />

                          <YAxis
                            // domain has to be in functions otherwise it doesnt update?
                            domain={[
                              this.getMinMaxVal(measurements_to_chart, site)
                                .min,
                              this.getMinMaxVal(measurements_to_chart, site)
                                .max,
                            ]} // 'auto' doesnt work instead of math.ceil
                            dataKey={"measurement_af"}
                            type="number"
                          >
                            <Label
                              value="Well Pumping (ac-ft)"
                              position="middle"
                              angle={-90}
                              offset={20}
                            />
                          </YAxis>
                          <Tooltip content={<CustomTooltip />} />
                          {/* <Tooltip
                        labelFormatter={(value) =>
                          new Date(value).toLocaleDateString()
                        }
                      /> */}
                          <Legend />
                          <Line
                            connectNulls
                            isAnimationActive={false} // Dots are broken on re-render if true https://github.com/recharts/recharts/issues/804
                            name={"well pumping (ac-ft)"}
                            type="linear"
                            dataKey={"measurement_af"}
                            stroke="#0080be"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </AppContainer>
      );
    }
  }
}
WellPumpingDetail = connect((state, ownProps) => ({}), {})(WellPumpingDetail);

export default withStyles(styles)(WellPumpingDetail);
