import React, { Component } from "react";
import { connect } from "react-redux";

//import PageHeader from '../common/PageHeader';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as authActions from "../auth/actions"; 

const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});



class MaintenanceLog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: []
        };
    }
    handleExport() {
        // Export well data to excel.
    }
    handleChartPrint() {
        // Print the current chart to PDF.
    }
    render() {
        const { error, isLoaded, item, itemres } = this.state;
        const { authState } = this.props; // authState seems to be here by magic! :-) //navigation
        if (!authState) return "...";        
        if (error) {
            return <div>Error loading Wells</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <Button
                        title={item.well_id}
                        onClick={() => this.handleNav(item, this.props.history)}
                    >Add New Maintenance</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Actions</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Performed By</TableCell>
                                <TableCell>Description</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {itemres.map(it => (
                                <TableRow>
                                    <TableCell><Button
                                        title={it.id}
                                        onClick={() => this.handleNav(item, this.props.history)}
                                    >View/Edit</Button></TableCell>
                                    <TableCell>Actions</TableCell>
                                    <TableCell>{it.date}</TableCell>
                                    <TableCell>{it.performedby}</TableCell>
                                    <TableCell>{it.description}</TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </div>
                
            );
        }
    }
}
MaintenanceLog = connect(
    state => ({
        navState: state.nav,
        authState: state.auth
    }),
    {
        ...authActions
    }
)(MaintenanceLog);

export default withStyles(styles)(MaintenanceLog); 
