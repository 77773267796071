import React, { Component } from "react";
import { WellSite } from "./models";
import classNames from "classnames";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter, Link } from "react-router-dom";
import { createSelector } from "../common/orm";

import { withStyles, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import ViewIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import TableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";

import NotReady from "../common/NotReady";
import WellSiteSummary from "../../api/wellsiteSummaryHOC";

import BreadcrumbNav from "../common/BreadCrumb";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  deleteWidth: {
    minWidth: 34,
    width: 34,
    marginRight: 16,
  },
  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  divideButton: {
    marginBottom: 8,
    width: "50%",
    marginTop: 16,
    minWidth: 44,
  },
  smallerIcon: {
    width: ".9em",
  },
  buttonSelected: {
    border: "3px solid " + theme.palette.primary.main,
    minWidth: "44px",
  },
  buttonNotSelected: {
    border: "3px solid #666",
    minWidth: "44px",
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
  iconNotSelected: {
    color: "#666",
  },
  gridHeight: {
    paddingRight: "0px",
  },
});

const columnData = [
  { id: "actions", numeric: false, label: "Actions", allowSort: false },
  { id: "dms_site_id", numeric: false, label: "DMS Site ID", allowSort: true },
  {
    id: "local_site_id",
    numeric: false,
    label: "Local Site ID or Name",
    allowSort: true,
  },
  {
    id: "source_gsa_label",
    numeric: false,
    label: "Source GSA",
    allowSort: true,
  },
  {
    id: "gsp_mon_network",
    numeric: false,
    label: "GSP Monitoring Network",
    allowSort: true,
  },
  {
    id: "num_measurements",
    numeric: true,
    label: "Total Number of Measurements",
    allowSort: true,
  },
  {
    id: "date_range",
    numeric: false,
    label: "Date Range of Measurements",
    allowSort: true,
  },
];

class WellPumpingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonState: "table",
      filter: null,
      order: "asc",
      orderBy: null,
      page: 0,
      rowsPerPage: 25,
    };
  }

  filterByValue = (array, string) => {
    return array.filter((o) =>
      Object.keys(o).some(
        (k) =>
          o[k] && o[k].toString().toLowerCase().includes(string.toLowerCase())
      )
    );
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.state.sites.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  handleAPIChange(data) {
    this.setState({
      sites: data.sites,
    });
  }

  render() {
    const { classes } = this.props;
    const { order, orderBy, filter, page, rowsPerPage, buttonState, sites } =
      this.state;

    var filtered_data;
    if (filter) {
      filtered_data = this.filterByValue(sites, filter);
    } else {
      filtered_data = sites;
    }

    if (sites === undefined) {
      return (
        <AppContainer authenticated>
          <WellSiteSummary
            apitarget={"wellpumpingmeasurements"}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer au authenticated>
          <Grid container spacing={24} className={classes.gridHeight}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1="Well Pumping"
                level1link="/wellpumpinglist"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Keyword Filter</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              style={{ backgroundColor: "#ddd", marginLeft: 12 }}
            >
              <Form dontValidateOnMount={true} validateOnSubmit={true}>
                {(formApi) => (
                  <form style={{ float: "left" }} onSubmit={formApi.submitForm}>
                    <TextField
                      rounded
                      style={{ borderRadius: 114 }}
                      placeholder="Filter Sites"
                      startAdornment={
                        <InputAdornment style={{ marginRight: 8 }}>
                          <SearchIcon />
                        </InputAdornment>
                      }
                      eventHandle={(val) => {
                        this.setState({ filter: val });
                      }}
                      field="filter"
                      label=""
                    />
                  </form>
                )}
              </Form>
            </Grid>
            <Grid item xs={12} style={{ "paddingRight": "0px" }}>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    columnData={columnData}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                  />
                  <TableBody>
                    {filtered_data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Tooltip title="View Site">
                              <Button
                                className={classes.deleteWidth}
                                component={Link}
                                to={"/wellpumpingdetail/" + item.id}
                              >
                                <ViewIcon color="primary" />
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell>{item.dms_site_id}</TableCell>
                          <TableCell>{item.local_site_id}</TableCell>
                          <TableCell>{item.source_gsa_label}</TableCell>
                          <TableCell>{item.gsp_mon_network}</TableCell>
                          <TableCell>{item.num_measurements}</TableCell>
                          <TableCell>{item.date_range}</TableCell>
                        </TableRow>
                      ))}
                    {filtered_data.length < 1 && (
                      <TableRow>
                        <TableCell colSpan={8} className={classes.centerAlign}>
                          No Water Level Sites Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {filtered_data.length > 25 && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={8}
                          count={filtered_data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </AppContainer>
      );
    }
  }
}

WellPumpingList = connect((state) => ({}), {})(WellPumpingList);

export default withStyles(styles)(withRouter(WellPumpingList));
