import React, { Component } from "react";
import { connect } from "react-redux";

import PageHeader from '../common/PageHeader';
import Button from "@material-ui/core/Button";

import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import * as authActions from "../auth/actions";

// React Tables
//import ReactTable from "react-table"; // Example: https://codesandbox.io/s/03x3r0vx1l



const styles = theme => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
});

class Graphs extends Component {
    render() {
        const { item } = this.props;
        return (
            <div>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Graphs</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <ObservationChart />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Summary Statistics</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TextField
                            id="period"
                            label="Period"
                            value={item.period}
                        />
                        <TextField
                            id="minval"
                            label="Minimum Value"
                            value={item.minval}
                        />
                        TextField
                            id="maxval"
                            label="Maximum Value"
                            value={item.maxval}
                        />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Filter Monitoring Data</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <TextField
                            id="minInput"
                            label="Min"
                            value={item.minval}
                        />
                        <TextField
                            id="maxInput"
                            label="Max"
                            value={item.maxval}
                        />
                        <Button
                            title="Export to Excel"
                            onclick={() => this.exportToExcel(item, this.props.history)}
                        >Export to Excel</Button>
                        <Button
                            title="Print Chart"
                            onclick={() => this.printChart(item, this.props.history)}
                        >Print Chart</Button>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        )
    }
}
Graphs.defaultProps = {
    item: {
        type: "",
        period: "",
        groundwaterelev: "",
        minval: "",
        maxval: ""
    }
}

// <Graphs />
class ObservationChart extends Component {
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        // Rerender the chart.
        // Original chart code found in /app/js/kings/chartwidget.js
    }
    render() {
        //const { type, filtermin, filtermax } = this.props; // Each of the types gets its own graph.
        const { item } = this.props;
        return (
            <div>
                <div>Chart Placeholder</div>
                
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>Graphs</ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Select
                            value={item.type}
                            onChange={this.handleChange}
                        >
                            <MenuItem value="flowrate">Flow Rate</MenuItem>
                            <MenuItem value="cfs">Flow Rate (CFS)</MenuItem>
                            <MenuItem value="afday">AF/Day</MenuItem>
                            <MenuItem value="staticwater">Static Water Level</MenuItem>
                            <MenuItem value="pumpingwater">Pumping Water Level</MenuItem>
                            <MenuItem value="discharge">Discharge Level Above Pipe</MenuItem>
                            <MenuItem value="tdh">TDH</MenuItem>
                            <MenuItem value="motorhorsepower">Horsepower</MenuItem>
                            <MenuItem value="waterhorsepower">Water Horsepower</MenuItem>
                            <MenuItem value="horsepower">Horsepower Input</MenuItem>
                            <MenuItem value="kilowatt">Kilowatt Hour Input</MenuItem>
                            <MenuItem value="specificcapacity">Specific Capacity</MenuItem>
                            <MenuItem value="efficiency">Efficiency</MenuItem>
                        </Select>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
}

ObservationChart.defaultProps = {
    item: {
        type: "",
    }
}

class PumpTests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded:false,
            item: []
        };
    }
    /*
    componentDidMount() {
        const { authState, navState } = this.props;
        const item = {
            id: navState.id,
            well_id: navState.well_id
        }; //this.props.location.item;
        fetch("/wellqualities/?well="+item.id, {
                headers: {
                    "Authorization":"Token " + authState.user.auth_token
                },
                credentials: "same-origin"
            })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.list.length === 0) {
                        this.setState({
                            isLoaded:true,
                            itemres: [],
                            item: item
                        });
                    } else {
                        console.log(result);
                        this.setState({
                            isLoaded:true,
                            itemres: result.list,
                            item: item
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded:false,
                        error
                    });
                }
            )
    }
    */
    handleExport() {
        // Export well data to excel.
    }
    handleChartPrint() {
        // Print the current chart to PDF.
    }
    render() {
        const { error, isLoaded, item, itemres } = this.state;
        const { authState } = this.props; 
        if (!authState) return "...";        
        if (error) {
            return <div>Error loading Wells</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div>
                    <PageHeader title={' Well Volume Pumps'} />
                    <ObservationChart />
                    <Button
                        title={item.well_id}
                        onClick={() => this.handleNav(item, this.props.history)}
                    >Add New Test Pump</Button>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Actions</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Tested By</TableCell>
                                <TableCell>Flow Rate <i>GPM</i></TableCell>
                                <TableCell>Flow Rate <i>CFS</i></TableCell>
                                <TableCell>AF/Day</TableCell>
                                <TableCell>Static Water Level <i>ft</i></TableCell>
                                <TableCell>Pumping Water Level <i>ft></i></TableCell>
                                <TableCell>Discharge Level Above Pipe <i>ft</i></TableCell>
                                <TableCell>TDH <i>does not include Hf</i></TableCell>
                                <TableCell>Horsepower <i>HP</i></TableCell>
                                <TableCell>Water Horsepower <i>HP</i></TableCell>
                                <TableCell>Horsepower Input <i>HP</i></TableCell>
                                <TableCell>Kilowatt Hour Input <i>kW-hr</i></TableCell>
                                <TableCell>kW-hr / AF-day <i>ft</i></TableCell>
                                <TableCell>Specific Gravity <i>GMP/ft-DD</i></TableCell>
                                <TableCell>Efficiency <i>%</i></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {itemres.map(it => (
                                <TableRow>
                                    <TableCell><Button
                                        title={it.id}
                                        onClick={() => this.handleNav(item, this.props.history)}
                                    >View/Edit</Button></TableCell>
                                    <TableCell>Actions</TableCell>
                                    <TableCell>{it.date}</TableCell>
                                    <TableCell>{it.testedby}</TableCell>
                                    <TableCell>{it.flowrate}</TableCell>
                                    <TableCell>{it.cfs}</TableCell>
                                    <TableCell>{it.afday}</TableCell>
                                    <TableCell>{it.staticwater}</TableCell>
                                    <TableCell>{it.pumpingwater}</TableCell>
                                    <TableCell>{it.discharge}</TableCell>
                                    <TableCell>{it.tdh}</TableCell>
                                    <TableCell>{it.motorhorsepower}</TableCell>
                                    <TableCell>{it.waterhorsepower}</TableCell>
                                    <TableCell>{it.horsepower}</TableCell>
                                    <TableCell>{it.kilowatt}</TableCell>
                                    <TableCell>{it.kwhr}</TableCell>
                                    <TableCell>{it.specificcapacity}</TableCell>
                                    <TableCell>{it.efficiency}</TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </div>
            );
        }
    }
}
PumpTests = connect(
    state => ({
        navState: state.nav,
        authState: state.auth
    }),
    {
        ...authActions
    }
)(PumpTests);

export default withStyles(styles)(PumpTests); 
