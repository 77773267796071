import React, { Component } from "react";
import WarningDialog from "./WarningDialog";
class WarningDialogWrapper extends Component {
    state = {
        open: true
    };
    componentWillReceiveProps() {
        this.setState({ open: true });
    }
    render() {
        const { callback, message } = this.props;
        const { open } = this.state;
        return (
            <WarningDialog
                open={open}
                cancelAction={() => {
                    callback(true);
                    this.setState({ open: false });
                }}
                confirmAction={() => {
                    callback(false);
                    this.setState({ open: false });
                }}
                confirmText="Stay on the Page"
                cancelText="Leave Page"
                title="Leave page without saving?"
                text={message}
            />
        );
    }
}
export default WarningDialogWrapper;