import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { FilePicker } from "react-file-picker";
import Icon from "@mdi/react";
import { mdiUpload, mdiCheckCircle } from "@mdi/js";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  btn: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  btnHover: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px",
    cursor: "pointer",
    outline: "1px dotted #000",
    outline: "-webkit-focus-ring-color auto 5px",
  },
});

class FileLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      fileUploaded: false,
    };
  }

  handlePick = (file, postURL, csrftoken) => {
    this.setState({ fileUploaded: false });

    let data = new FormData();
    data.append("spreadsheet", file);
    fetch(postURL, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: "Token " + csrftoken,
      },
      body: data,
    })
      .then((res) => res.json())
      .then((response) => {
        this.props.onUpload(response.id);
        if (response.id) {
          this.setState({ fileUploaded: true });
        } else {
          this.setState({ fileUploaded: false });
        }
      })
      .catch((err) => this.setState({ fileUploaded: false }));
  };

  getInitialState = () => {
    return { hover: false };
  };

  toggleHover = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    const { postURL, csrftoken, classes } = this.props;

    return (
      <FilePicker
        onChange={(FileObject) =>
          this.handlePick(FileObject, postURL, csrftoken)
        }
        onError={(errMsg) => console.log(errMsg)}
      >
        <Grid item xs={12}>
          <Button
            fullWidth
            className={this.state.hover ? classes.btnHover : classes.btn}
            onMouseEnter={this.toggleHover}
            onMouseLeave={this.toggleHover}
          >
            <Icon path={mdiUpload} size={1} color="white"></Icon> Choose a file
            to upload
            {this.state.fileUploaded && (
              <Icon
                style={{
                  marginLeft: "12px",
                  verticalAlign: "middle",
                }}
                path={mdiCheckCircle}
                size={1}
                color="#28a745"
              ></Icon>
            )}
          </Button>
        </Grid>
      </FilePicker>
    );
  }
}

export default withStyles(styles)(FileLoader);
