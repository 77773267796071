import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NotReady from "../common/NotReady";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import TextField from "../common/TextField";
import { Form } from "react-form";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@mdi/react";
import { WaterYear } from "./models";
import { mdiUndo, mdiFileDocument } from "@mdi/js";
import AdminPasswordHOC from "../../api/adminPasswordHOC";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://sgp-dms.houstoneng.net";

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  error: {
    color: "red",
  },
});

class AdminEditUserPW extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      errorMessage: "",
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  handleAPIChange(data) {
    this.setState({ existingData: data });
  }

  handleChange(evt) {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  errorValidator = (values) => {
    const validatePassword = (new_password1, new_password2) => {
      if (new_password1 !== new_password2) return "Passwords do not match";
      else if (!new_password1 || new_password1.length < 8)
        return "Invalid password";
      else return null;
    };
    var valObj = {};

    valObj["new_password1"] = validatePassword(
      values.new_password1,
      values.new_password2
    );
    valObj["new_password2"] = validatePassword(
      values.new_password1,
      values.new_password2
    );

    return valObj;
  };

  submitForm = (values) => {
    const { history, authState } = this.props;
    const { existingData } = this.state;

    let payload = {
      id: existingData.uid,
      token: existingData.token,
      new_password1: values.new_password1,
      new_password2: values.new_password2,
    };

    let request_token =
      authState && authState.user ? authState.user.auth_token : "";

    this.setState({ ...this.state, isFetching: true });

    let URL = isLocalhost ? LOCAL_URL : PROD_URL;

    fetch(URL + "/passwordreset/confirm/", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Token " + request_token,
      },
    })
      .then((response) => {
        this.setState({
          ...this.state,
          isFetching: false,
        });
        if (response.ok) {
          history.push("/adminusers/" + existingData.uid);
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({
          ...this.state,
          isFetching: false,
          errorMessage: e.message,
        });
      });
  };

  render() {
    const { classes, history, match } = this.props;
    const { isFetching, existingData } = this.state;

    if (existingData === undefined) {
      return (
        <AppContainer authenticated>
          <AdminPasswordHOC
            apitarget={"token/" + match.params["id"]}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid container spacing={24}>
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1="Administration"
                level1link="/administration"
                level2="Manage Users"
                level2link="/adminusers"
                level3={(this.is_new ? "Add" : "Edit") + " User"}
                level3link={"/adminusers/" + match.params["id"]}
                level4={"Set Password"}
                level4link={
                  "/adminusers/" + match.params["id"] + "/resetpassword"
                }
              />
            </Grid>
            <Grid item xs={12} mg={9} lg={6} xl={3}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => history.push("/administration")}
              >
                <Icon path={mdiUndo} size={1} color="white"></Icon>
                &nbsp;&nbsp;&nbsp; Return to Administration
              </Button>
            </Grid>
            <Form
              dontValidateOnMount="true"
              validateOnSubmit="true"
              validateError={this.errorValidator}
              onSubmit={this.submitForm}
            >
              {(formApi) => (
                <Grid item xs={12}>
                  <form onSubmit={formApi.submitForm}>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField
                        name="new_password1"
                        type="password"
                        field="new_password1"
                        label="Password*"
                        fullWidth
                      />
                      <TextField
                        name="new_password2"
                        type="password"
                        field="new_password2"
                        label="Confirm Password*"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                        disabled={isFetching}
                      >
                        Save
                      </Button>
                    </Grid>
                    {this.state.errorMessage && (
                      <h3 className={classes.error}>
                        {this.state.errorMessage}
                      </h3>
                    )}
                  </form>
                </Grid>
              )}
            </Form>
          </Grid>
        </AppContainer>
      );
    }
  }
}

AdminEditUserPW = connect((state, ownProps) => ({
  authState: state.auth,
}))(AdminEditUserPW);
export default withStyles(styles)(withRouter(AdminEditUserPW));
