import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import Grid from "@material-ui/core/Grid";
import BreadcrumbNav from "../common/BreadCrumb";
import FileLoader from "./FileLoader";

import AppContainer from "../common/AppContainer";
import Button from "@material-ui/core/Button";
import Icon from "@mdi/react";
import { mdiDownload } from "@mdi/js";
import NotReady from "../common/NotReady";
import requestConfig from "../../api/fetchConfig";

import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "../common/TableCell";

const styles = (theme) => ({
  buttons: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    marginRight: "15px",
    marginTop: "10px",
    marginBottom: "10px",
  },
  container: {
    paddingLeft: "10px",
  },
  hrclass: {
    borderColor: theme.palette.primary.main,
  },
  lgHeader: {
    fontSize: "25px",
  },
  mdHeader: {
    fontSize: "18px",
    textDecoration: "underline",
    fontWeight: "normal",
  },
  btnValidateInactive: {
    marginTop: "10px",
    backgroundColor: "#7ed2f5",
    color: "#fff !important",
  },
  btnValidateActive: {
    marginTop: "10px",
    backgroundColor: theme.palette.primary.main,
    color: "#fff !important",
  },
  progressActive: {
    display: "block",
  },
  progressInactive: {
    display: "none",
  },
});

class StatusBar extends Component {
  render() {
    const { status, data, colErrors } = this.props;

    if (status === "SUCCESS") {
      if (data.skipped !== undefined && data.skipped.length > 0) {
        return (
          <div>
            <h2>
              {data.current} of {data.total} records were read, but{" "}
              {data.skipped.length} records did not upload due to the following
              errors:
            </h2>
            <br />
            <Grid item xs={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <b>Data Row</b>
                  </TableCell>
                  <TableCell>
                    <b>Data Column</b>
                  </TableCell>
                  <TableCell>
                    <b>Error Message</b>
                  </TableCell>
                </TableRow>
                {data.skipped.map(function (row) {
                  const d = JSON.parse(row.reason);
                  return Object.keys(d).map((key) => {
                    return (
                      <TableRow>
                        <TableCell>{row.row}</TableCell>
                        <TableCell>{key}</TableCell>
                        <TableCell>{d[key]}</TableCell>
                      </TableRow>
                    );
                  });
                })}
              </Table>
            </Grid>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: "center" }}>
            {" "}
            {data.current} of {data.total} Records Loaded.
          </div>
        );
      }
    } else if (status === "PROGRESS") {
      return (
        <div>
          {" "}
          <div style={{ textAlign: "center" }}>
            {data.current} of {data.total} Records Uploading...
          </div>
        </div>
      );
    } else if (status === "FAILURE") {
      if (colErrors.length) {
        return (
          <>
            <div>
              <h2>Column Mapping Failure:</h2>
            </div>
            <Grid item xs={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <b>Column Name</b>
                  </TableCell>
                  <TableCell>
                    <b>Error Message</b>
                  </TableCell>
                </TableRow>
                {colErrors.map(function (col) {
                  return (
                    <TableRow>
                      <TableCell>{col.field_name}</TableCell>
                      <TableCell>{col.match}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Grid>

            <div>
              <h2>Acceptable column values for this dataset are:</h2>
            </div>
            <Grid item xs={12}>
              <Table>
                <TableRow>
                  <TableCell>
                    <b>Column ID</b>
                  </TableCell>
                  <TableCell>
                    <b>Column Label</b>
                  </TableCell>
                </TableRow>
                {colErrors.length &&
                  colErrors[0].types.length &&
                  colErrors[0].types[0].choices.map(function (col) {
                    return (
                      <TableRow>
                        <TableCell>{col.id}</TableCell>
                        <TableCell>{col.label}</TableCell>
                      </TableRow>
                    );
                  })}
              </Table>
            </Grid>
          </>
        );
      } else {
        if (status !== undefined) {
          if (data && data.error !== undefined) {
            return (
              <div>
                <h2>
                  {status} : {data.error}
                </h2>
              </div>
            );
          } else if (data && data.detail !== undefined) {
            return (
              <div>
                <h2>
                  {status} : {data.detail}
                </h2>
              </div>
            );
          } else {
            return (
              <div>
                <h2>{status}</h2>
              </div>
            );
          }
        } else {
          return (
            <div>
              <h2>{data.error}</h2>
            </div>
          );
        }
      }
    } else {
      if (status !== undefined) {
        return <div> {status} </div>;
      } else {
        return <div> {data.error} </div>;
      }
    }
  }
}

class ImportMonitoringDataGSA extends Component {
  constructor(props) {
    super(props);

    this.state = {
      csrftoken: null,
      tracking_set: false,
    };
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (authState && authState.user && authState.user.role === "Agency") {
      history.push("/dashboard");
    }
  }

  handleAPIChange(data) {
    if (data.user_role === "Administrator") {
      this.setState({ authorized: true });
    }
  }

  submitMonitoringData = (e) => {
    console.log(e);
    console.log("Monitoring Data Submitted");
    this.setState({
      all_sumitted: false,
    });
  };

  downloadTemplate = (fileName) => {
    window.open(
      "https://sgp-dms.houstoneng.net/media/templates/" + fileName,
      "_newtab"
    );
  };

  downloadImportTemplate = (section) => {
    switch (section) {
      case "WLM":
        this.downloadTemplate("water_level_data.xlsx");
        break;
      case "WQ":
        this.downloadTemplate("water_quality_data.xlsx");
        break;
      case "WP":
        this.downloadTemplate("well_pumping_data.xlsx");
        break;
      case "surf":
        this.downloadTemplate("sw_gw_interaction_data.xlsx");
        break;
      case "sub":
        this.downloadTemplate("subsidence_data.xlsx");
        break;
      case "stream":
        this.downloadTemplate("stream_monitoring_data.xlsx");
        break;
      default:
        break;
    }
  };

  viewRecords = (object_id) => {
    console.log("Running Status Check");
    fetch("/datawizard/" + object_id + "/records", {
      credentials: "same-origin",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        /* Data is coming back as "Status:UNKNOWN" which is probably pending. */
      })
      .catch((err) => {
        this.setState({
          isImportButtonActive: false,
          isProgressActive: false,
        });
        console.log(err);
      });
  };

  trackRunStatus = (section) => {
    const object_id = this.state["tracking"][section]["object_id"];
    const task_id = this.state["tracking"][section]["task_id"];

    console.log("Running Status Check");
    fetch("/datawizard/" + object_id + "/status.json?task=" + task_id, {
      credentials: "same-origin",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);

        let { tracking } = this.state;
        tracking[section]["data"] = data;
        tracking[section]["status"] = data.status;
        this.setState({ tracking: tracking });

        if (["SUCCESS", "FAILURE"].indexOf(data.status) > -1) {
          tracking[section]["isProgressActive"] = false;
          this.setState({ tracking: tracking });
        } else if (["PROGRESS"].indexOf(data.status) > -1) {
          tracking[section]["completed"] = (data.current / data.total) * 100;

          this.setState({ completed: (data.current / data.total) * 100 });
          this.setState({ tracking: tracking });

          setTimeout(() => {
            this.trackRunStatus(section);
          }, 1000);
        }
      })
      .catch((err) => {
        let { tracking } = this.state;
        tracking[section]["isImportButtonActive"] = false;
        tracking[section]["isProgressActive"] = false;
        this.setState({ tracking: tracking });

        console.log(err);
      });
  };

  getRunColumns = (section) => {
    let { tracking } = this.state;

    const object_id = tracking[section]["object_id"];

    fetch("/datawizard/" + object_id + "/columns", {
      credentials: "same-origin",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.result && data.result.unknown_count > 0) {
          tracking[section]["colErrors"] = data.result.columns;
          tracking[section]["progressType"] = "determinate";

          this.setState({ tracking: tracking });
        }
      })
      .catch((err) => {
        tracking[section]["isImportButtonActive"] = false;
        tracking[section]["isProgressActive"] = false;

        this.setState({ tracking: tracking });

        console.log(err);
      });
  };

  startDataRun = (section) => {
    console.log("Start Data Run");

    let { tracking } = this.state;
    tracking[section]["isImportButtonActive"] = true;
    tracking[section]["isProgressActive"] = true;

    this.setState({
      tracking: tracking,
    });

    const content_type_id = "wells.filemodel";
    const serializer = tracking[section]["serializer"];
    const object_id = tracking[section]["object_id"];

    console.log(this.state);

    fetch("/datawizard.json", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        object_id: object_id,
        content_type_id: content_type_id,
        serializer: serializer,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.id !== null && data.id !== undefined) {
          tracking[section]["object_id"] = data.id;
          this.setState({ tracking: tracking });
          this.runData(section);
        } else {
          tracking[section]["isImportButtonActive"] = false;
          tracking[section]["isProgressActive"] = false;
          tracking[section]["status"] = "FAILURE";
          tracking[section]["data"] = data;

          this.setState({
            tracking: tracking,
          });
        }
      })
      .catch((err) => {
        tracking[section]["isImportButtonActive"] = false;
        tracking[section]["isProgressActive"] = false;

        this.setState({
          tracking: tracking,
        });

        console.log(err);
      });
  };

  runData = (section) => {
    let { tracking } = this.state;

    const object_id = tracking[section]["object_id"];

    fetch("/datawizard/" + object_id + "/data", {
      credentials: "same-origin",
      method: "POST",
      headers: {
        Authorization: "Token " + this.state.csrftoken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        tracking[section]["task_id"] = data.task_id;

        this.setState({ tracking: tracking });

        console.log(data);

        if (data.task_id !== null && data.task_id !== undefined) {
          this.getRunColumns(section);
          setTimeout(() => {
            this.trackRunStatus(section);
          }, 3000);
        }
      })
      .catch((err) => {
        tracking[section]["isImportButtonActive"] = false;
        tracking[section]["isProgressActive"] = false;

        this.setState({ tracking: tracking });

        console.log(err);
      });
  };

  onUpload = (object_id, section) => {
    console.log(section);
    let { tracking } = this.state;

    console.log("Uploaded object with id: " + object_id);

    tracking[section]["object_id"] = object_id;
    tracking[section]["isImportButtonActive"] = false;
    tracking[section]["data"] = null;
    tracking[section]["status"] = null;

    this.setState({
      tracking: tracking,
    });
  };

  setAuthorized = () => {
    const { authState } = this.props;

    this.setState({
      csrftoken: authState.user.auth_token,
    });
  };

  setTracking = () => {
    const sections = ["WLM", "WP"];

    const section_details = {
      WLM: {
        serializer: "wells.serializers.WellSiteMeasurementSerializer",
        importTarget: "Water Level Data",
      },
      WP: {
        serializer: "wells.serializers.WellPumpingMeasurementSerializer",
        importTarget: "Well Pumping Data",
      },
    };

    const { appConfig } = this.state;

    if (appConfig) {
      let tracking = {};

      sections.map((section) => {
        if (appConfig[section]["enabled"]) {
          let temp_tracking = {
            object_id: null,
            task_id: null,
            status: null,
            data: [],
            colErrors: [],
            completed: 0,
            progressType: "indeterminate",
            isImportButtonActive: false,
            isProgressActive: false,
            serializer: section_details[section]["serializer"],
            importTarget: section_details[section]["importTarget"],
            section: "",
          };

          tracking[section] = temp_tracking;
        }
      });

      this.setState({
        tracking: tracking,
        tracking_set: true,
      });
    }
  };

  render() {
    const {
      authorized,
      appConfig,
      isFetching,
      tracking,
      tracking_set,
      csrftoken,
    } = this.state;
    const { classes, authState } = this.props;

    if (csrftoken == null) {
      this.setAuthorized();
    }

    if (!tracking_set) {
      this.setTracking();
    }

    //get the config data
    if (csrftoken !== null && appConfig === undefined && !isFetching) {
      this.setState({ ...this.state, isFetching: true });
      requestConfig(csrftoken).then((data) => {
        this.setState({ appConfig: data, isFetching: false });
      });
    }

    if (appConfig === undefined || tracking === undefined) {
      return (
        <AppContainer authenticated>
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <div className={classes.container}>
            <BreadcrumbNav
              level1="Import Monitoring Data"
              level1link="/import"
            />
            <br />
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <span className={classes.lgHeader}>Import Monitoring Data</span>
              </Grid>

              {Object.keys(tracking).map((section, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Card>
                      <CardHeader title={tracking[section]["importTarget"]} />

                      <CardContent>
                        <Grid container spacing={24}>
                          <Grid item xs={12} md={4}>
                            <Button
                              fullWidth
                              className={classes.buttons}
                              onClick={() =>
                                this.downloadImportTemplate(section)
                              }
                              style={{
                                backgroundColor: "primary",
                                color: "white",
                              }}
                            >
                              <Icon
                                path={mdiDownload}
                                size={1}
                                color="white"
                              ></Icon>{" "}
                              Download Spreadsheet Template
                            </Button>
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <FileLoader
                              postURL="/filemodels.json"
                              csrftoken={csrftoken}
                              onUpload={(object_id) =>
                                this.onUpload(object_id, section)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} md={4}>
                            <Button
                              fullWidth
                              className={
                                tracking[section]["object_id"] === null ||
                                tracking[section]["isImportButtonActive"]
                                  ? classes.btnValidateInactive
                                  : classes.btnValidateActive
                              }
                              disabled={
                                tracking[section]["object_id"] === null ||
                                tracking[section]["isImportButtonActive"]
                              }
                              onClick={() => this.startDataRun(section)}
                            >
                              {" "}
                              Begin Validate and Import{" "}
                            </Button>
                          </Grid>

                          {tracking[section]["isProgressActive"] && (
                            <Grid item xs={12}>
                              <LinearProgress
                                variant={tracking[section]["progressType"]}
                                className={
                                  tracking[section]["isProgressActive"]
                                    ? classes.progressActive
                                    : classes.progressInactive
                                }
                                value={tracking[section]["completed"]}
                              />
                            </Grid>
                          )}

                          {tracking[section]["status"] != null && (
                            <Grid item xs={12}>
                              <StatusBar
                                object_id={tracking[section]["object_id"]}
                                task_id={tracking[section]["task_id"]}
                                completed={tracking[section]["completed"]}
                                trackRunStatus={this.trackRunStatus.bind(this)}
                                viewRecords={"this.viewRecords.bind(this)"}
                                status={tracking[section]["status"]}
                                data={tracking[section]["data"]}
                                colErrors={tracking[section]["colErrors"]}
                                import_target={
                                  tracking[section]["importTarget"]
                                }
                              />
                            </Grid>
                          )}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </AppContainer>
      );
    }
  }
}

ImportMonitoringDataGSA = connect(
  (state, ownProps) => ({
    authState: state.auth,
  }),
  {}
)(ImportMonitoringDataGSA);
export default withStyles(styles)(withRouter(ImportMonitoringDataGSA));
