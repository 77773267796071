import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";
import { connect } from "react-redux";

import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Submit from "../common/Submit";
import Select from "../common/Select";
import states from "../common/states.json";
import TextField from "../common/TextField";
import * as authActions from "../auth/actions";

const styles = {
  registerError: {
    color: "red",
    textAlign: "center"
  }
};

class UserProfile extends Component {
  errorValidator = values => {
    const validateName = name => {
      return !name ? "Field is required" : null;
    };
    return {
      first_name: validateName(values.first_name),
      last_name: validateName(values.last_name),
      phone: validateName(values.phone)
    };
  };
  errorValidatorPassword = values => {
    const validatePasswordSingle = password => {
      if (!password || password.length < 8) return "Invalid password";
      else return null;
    };
    const validatePassword = (password, password2) => {
      if (password !== password2) return "Passwords do not match";
      else if (!password || password.length < 8) return "Invalid password";
      else return null;
    };
    return {
      current_password: validatePasswordSingle(values.current_password),
      new_password: validatePassword(
        values.new_password,
        values.re_new_password
      ),
      re_new_password: validatePassword(
        values.new_password,
        values.re_new_password
      )
    };
  };
  submitForm = values => {
    this.props.authUpdate(values).then(() => {
      if (this.props.update.success) {
        this.props.history.push("/dashboard");
      }
    });
  };
  submitFormPassword = values => {
    this.props.authPasswordChange(values).then(() => {
      if (this.props.change.success) {
        this.props.history.push("/dashboard");
      }
    });
  };

  render() {
    const { classes, update, change, authState } = this.props;
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            defaultValues={authState.user}
            validateError={this.errorValidator}
            onSubmit={this.submitForm}
          >
            {formApi => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <Grid container spacing={40}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.marginTop}
                      >
                        User Profile
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={40} gutterBottom>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField
                        field="email"
                        label="Email (username)"
                        disabled
                        fullWidth
                      />
                      <TextField
                        field="first_name"
                        label="First Name*"
                        fullWidth
                      />
                      <TextField
                        field="last_name"
                        label="Last Name*"
                        fullWidth
                      />
                      <TextField
                        field="phone"
                        label="Telephone*"
                        placeholder="555-555-5555"
                        fullWidth
                      />
                      <TextField field="address" label="Address" fullWidth />
                      <TextField field="address2" label="Address 2" fullWidth />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField field="city" label="City" fullWidth />
                      <Select
                        field="state"
                        label="State"
                        options={states}
                        fullWidth
                      />
                      <TextField
                        field="zip"
                        label="Zip Code"
                        placeholder="55555"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={40}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Submit
                        label="Update User Information"
                        altAction="/dashboard"
                        altLabel="Return to Dashboard"
                        loading={update.pending}
                      />
                      <Typography className={classes.registerError}>
                        {update.error}
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
          <Grid item xs={12} md={8} lg={6}>
            <Divider />
          </Grid>
          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            validateError={this.errorValidatorPassword}
            onSubmit={this.submitFormPassword}
          >
            {formApi => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <Grid container spacing={40}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Typography variant="h6" gutterBottom>
                        Update Password
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={40} gutterBottom>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField
                        field="current_password"
                        label="Current Password"
                        type="password"
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField
                        field="new_password"
                        label="New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                      />
                      <TextField
                        field="re_new_password"
                        label="Retype New Password"
                        type="password"
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={40}>
                    <Grid item xs={12} md={8} lg={6}>
                      <Submit
                        label="Update Password"
                        altAction="/dashboard"
                        altLabel="Return to Dashboard"
                        loading={change.pending}
                      />
                      <Typography className={classes.registerError}>
                        {change.error}
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </AppContainer>
    );
  }
}
UserProfile = connect(
  state => ({
    authState: state.auth,
    update: (state.auth && state.auth.update) || {},
    change: (state.auth && state.auth.change) || {}
  }),
  authActions
)(UserProfile);

export default withStyles(styles)(withRouter(UserProfile));
