import {
  ORM,
  Model as ORMModel,
  ForeignKey,
  createSelector as ormSelector,
} from "redux-orm";
import { AUTH_LOGOUT } from "../auth/actions";

const orm = new ORM();
export default orm;

export function createSelector(...args) {
  return ormSelector(orm, (state) => state.orm, ...args);
}

export function selectAll(cls, orderBy, serialize = (obj) => obj.ref) {
  return createSelector((schema) =>
    schema[cls.modelName].all().orderBy(orderBy).toModelArray().map(serialize)
  );
}

export function selectByUrlId(cls, serialize = (obj) => obj.ref, param = "id") {
  return createSelector(
    (state, ownProps) => ownProps.match.params[param],
    (schema, objId) => {
      cls = schema[cls.modelName];
      if (cls.idExists(objId)) {
        var obj = cls.withId(objId);
        return serialize(obj);
      } else {
        // FIXME: Render a 404 page?
        return { name: "Not Found" };
      }
    }
  );
}

export function getOptions(cls, serialize = (obj) => obj.ref) {
  return createSelector(
    (state, ownProps) =>
      state.auth && state.auth.user ? state.auth.user.auth_token : {},
    (session, token) => {
      if (typeof token === "string") {
        return fetch(`${cls.source}/?t=${Date.now()}`, {
          method: "OPTIONS",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Token " + token,
          },
        })
          .then((result) => result.json())
          .then((data) => {
            if (data.actions && data.actions.POST) {
              data = data.actions.POST;
            }
            return data;
          });
      }
    }
  );
}

function isRealUser(authState) {
  return authState && authState.user && !authState.user.guest;
}

export class BaseModel extends ORMModel {
  static selectAll(serializer) {
    return selectAll(this, serializer);
  }

  static get apiUrl() {
    return "/";
  }

  static selectByUrlId(serializer, param = "id") {
    return selectByUrlId(this, serializer, param);
  }

  static getOptions() {
    return getOptions(this);
  }

  static get source() {
    return null;
  }

  static get isUserData() {
    return null;
  }

  static get initialLoad() {
    return true;
  }

  static get actions() {
    const functionPrefix = `orm${this.modelName}`,
      typePrefix = `ORM_${this.modelName.toUpperCase()}`;
    return {
      [`${functionPrefix}Reload`]: () => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState(); /*,
                        session = orm.session(state.orm);*/
          if (cls.isUserData) {
            if (!isRealUser(state.auth)) {
              dispatch({
                type: `${typePrefix}_GUESTDATA`,
                payload: cls.defaultData || [],
              });
              return;
            }
          } else {
          } /*else {
                        // FIXME: Use versioning to determine whether a reload
                        // is necessary.  For now, always reload.
                        if (session[cls.modelName].count() > 0) {
                            // return;
                        }
                    }*/
          dispatch({
            type: `${typePrefix}_PULLING`,
          });
          let options = {};
          options.headers = {
            Authorization: "Token " + state.auth.user.auth_token,
          };
          // Hacking a bit here. Manually pulling 2500 records.
          fetch(`${cls.source}?format=json&limit=6000&t=${Date.now()}`, options)
            .then((result) => result.json())
            .then((data) => {
              if (data.list) {
                data = data.list;
              }
              if (!(data instanceof Array)) {
                throw new Error(data.detail || data);
              }
              return data;
            })
            .then((data) =>
              dispatch({
                type: `${typePrefix}_PULLED`,
                payload: data,
              })
            )
            .catch((e) => {
              if (e.message === "Invalid token.") {
                dispatch({
                  type: AUTH_LOGOUT,
                });
              }
              dispatch({
                type: `${typePrefix}_PULLERROR`,
                error: e,
              });
            });
        };
      },
    };
  }

  static fromResponse(data) {
    return data;
  }

  static reducer(action, cls) {
    const prefix = `ORM_${cls.modelName.toUpperCase()}`;
    switch (action.type) {
      case `${prefix}_PULLED`:
      case `${prefix}_GUESTDATA`:
        const ids = action.payload.map((obj) => obj.id);
        cls.exclude((obj) => ids.includes(obj.id)).delete();
        action.payload.forEach((obj) => cls.upsert(cls.fromResponse(obj)));
        break;
      default:
        break;
    }
  }
}

export class ReadOnlyModel extends BaseModel {
  static get fields() {
    return {};
  }
  static get source() {
    return `/api/v3/ReferenceData/${this.modelName}`;
  }
  static get isUserData() {
    return false;
  }
}

export class Model extends BaseModel {
  static get source() {
    return `${this.pluralName}`; ///api/db/
  }

  static get pluralName() {
    return `${this.modelName.toLowerCase()}s`;
  }

  static get isUserData() {
    return true;
  }

  static createAction({ type, payload, effectIfLoggedIn, generateId }) {
    return (dispatch, getState) => {
      const state = getState();
      const { auth } = state;
      if (generateId) {
        if (!payload) {
          payload = {};
        }
        let code = this.generateCode(payload, state);
        if (code) {
          payload.code = code;
        }
        payload.id = this.generateId(payload);
      }
      let action = {
        type: type,
        payload: payload,
      };
      if (isRealUser(auth)) {
        action.meta = {
          offline: {
            effect: {
              ...effectIfLoggedIn,
              body: JSON.stringify(this.toRequest(payload)),
              headers: {
                Accept: "application/json",
                Authorization: "Token " + auth.user.auth_token,
              },
            },
            commit: {
              type: `${type}_PUSHED`,
            },
            rollback: {
              type: `${type}_PUSHERROR`,
              meta: { objectId: payload.id },
            },
          },
        };
      }
      dispatch(action);
      if (generateId) {
        return payload.id;
      }
    };
  }

  static fail(message) {
    message = `FPP ORM Error: ${message}`;
    console.error(message);
    throw new Error(message);
  }

  static _getForeignKeyInfo(payload, required = true) {
    const payloadName = `orm${this.modelName}Create payload`;

    if (!payload) {
      if (required) {
        this.fail(`Missing ${payloadName}`);
      } else {
        return [null, null];
      }
    }

    // Find foreign key field(s)
    const fks = Object.entries(this.fields).filter(
      ([, field]) => field instanceof ForeignKey
    );

    if (fks.length < 1) {
      if (required) {
        this.fail(`No foreign key on ${this.modelName}`);
      } else {
        return [null, null];
      }
    } else if (fks.length > 1) {
      if (required) {
        this.fail(`Multiple foreign keys on ${this.modelName}`);
      } else {
        return [null, null];
      }
    }

    const [fkname] = fks[0],
      fkvalue = payload[fkname];

    if (!fkvalue && required) {
      this.fail(`Expected ${fkname} in ${payloadName}`);
    }
    return [fkname, fkvalue];
  }

  static generateCode(payload, state) {
    const [fkname, fkvalue] = this._getForeignKeyInfo(payload, true),
      session = orm.session(state.orm);

    let maxCode = 0;
    session[this.modelName]
      .filter({
        [fkname]: fkvalue,
      })
      .toRefArray()
      .forEach((row) => {
        const lastCode = +row.code;
        if (lastCode > maxCode) {
          maxCode = lastCode;
        }
      });
    let nextCode = `${maxCode + 1}`;
    while (nextCode.length < this.codeWidth) {
      nextCode = `0${nextCode}`;
    }

    return nextCode;
  }

  static generateId(payload) {
    const payloadName = `orm${this.modelName}Create payload`,
      [, fkvalue] = this._getForeignKeyInfo(payload, false);

    if (payload.id) {
      this.fail(`Unexpected id in ${payloadName}`);
    }

    if (fkvalue) {
      return `${fkvalue}-${payload.code}`;
    } else {
      return payload.code;
    }
  }

  static get codeWidth() {
    return 0;
  }

  static toRequest(data) {
    var req = {};
    Object.entries(data).forEach(([key, value]) => {
      if (this.fields[key] instanceof ForeignKey) {
        req[key + "_id"] = value;
      } else if (!(key in req)) {
        req[key] = value;
      }
    });
    return req;
  }

  static fromResponse(data) {
    Object.keys(data).forEach((key) => {
      const field = key.replace(/_id$/, "");
      if (field !== key && this.fields[field] instanceof ForeignKey) {
        data[field] = data[key];
        delete data[key];
      }
    });
    return data;
  }

  static get actions() {
    const functionPrefix = `orm${this.modelName}`,
      typePrefix = `ORM_${this.modelName.toUpperCase()}`,
      baseActions = super.actions;

    return {
      [`${functionPrefix}Create`]: (payload) => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState(),
            { auth } = state;
          if (!isRealUser(auth)) {
            dispatch({
              type: `${typePrefix}_CREATE_PUSHERROR`,
              error: new Error("Not logged in."),
            });
            return;
          }
          dispatch({
            type: `${typePrefix}_CREATE_PUSHING`,
          });
          return fetch(`${cls.source}?format=json&t=${Date.now()}`, {
            method: "POST",
            body: JSON.stringify(cls.toRequest(payload)),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Token " + auth.user.auth_token,
            },
          })
            .then((result) => {
              if (!result.ok) {
                return result.text().then((text) => {
                  throw new Error(text);
                });
              } else {
                return result.json();
              }
            })
            .then((data) => {
              data = cls.fromResponse(data);
              dispatch({
                type: `${typePrefix}_CREATE`,
                payload: data,
              });
              dispatch({
                type: `${typePrefix}_CREATE_PUSHED`,
              });
              return data.id;
            })
            .catch((e) =>
              dispatch({
                type: `${typePrefix}_CREATE_PUSHERROR`,
                error: e,
              })
            );
        };
      },
      [`${functionPrefix}CreateLocalOnly`]: (payload) =>
        this.createAction({
          type: `${typePrefix}_CREATE`,
          payload: payload,
          generateId: false,
        }),
      [`${functionPrefix}CreateRemoteFirst`]: (payload) => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState(),
            { auth } = state;
          if (!isRealUser(auth)) {
            dispatch({
              type: `${typePrefix}_CREATE_PUSHERROR`,
              error: new Error("Not logged in."),
            });
            return;
          }
          dispatch({
            type: `${typePrefix}_CREATE_PUSHING`,
          });
          return fetch(`${cls.source}?format=json&t=${Date.now()}`, {
            method: "POST",
            body: JSON.stringify(cls.toRequest(payload)),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Token " + auth.user.auth_token,
            },
          })
            .then((result) => {
              if (!result.ok) {
                return result.text().then((text) => {
                  throw new Error(text);
                });
              } else {
                return result.json();
              }
            })
            .then((data) => {
              dispatch({
                type: `${typePrefix}_CREATE`,
                payload: data,
              });
              dispatch({
                type: `${typePrefix}_CREATE_PUSHED`,
              });
              return data.id;
            })
            .catch((e) =>
              dispatch({
                type: `${typePrefix}_CREATE_PUSHERROR`,
                error: e,
              })
            );
        };
      },
      [`${functionPrefix}LoadDetail`]: (id, callback) => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState();
          dispatch({
            type: `${typePrefix}_DETAIL_LOADING`,
          });
          let options = {};
          options.headers = {
            Authorization: "Token " + state.auth.user.auth_token,
          };
          fetch(`${cls.source}/${id}?format=json`, options)
            .then((result) => result.json())
            .then((data) => {
              if (data.list) {
                data = data.list;
              }
              // Data comes back as an object - needs to be an array?
              if (!(data instanceof Object)) {
                throw new Error(data.detail || data);
              }
              return data;
            })
            .then((data) => {
              dispatch({
                type: `${typePrefix}_DETAIL_LOADED`,
                payload: data,
              });
              if (callback) callback(data);
            })
            .catch((e) => {
              dispatch({
                type: `${typePrefix}_DETAIL_ERROR`,
                error: e,
              });
            });
        };
      },
      [`${functionPrefix}LoadRelated`]: (id, callback) => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState();
          dispatch({
            type: `${typePrefix}_RELATED_LOADING`,
          });
          let options = {};
          options.headers = {
            Authorization: "Token " + state.auth.user.auth_token,
          };
          fetch(`${cls.source}/?${id}&format=json`, options)
            .then((result) => result.json())
            .then((data) => {
              if (data.list) {
                data = data.list;
              }
              // Data comes back as an object - needs to be an array?
              if (!(data instanceof Object)) {
                throw new Error(data.detail || data);
              }
              return data;
            })
            .then((data) => {
              dispatch({
                type: `${typePrefix}_RELATED_LOADED`,
                payload: data,
              });
              if (callback) callback(data);
            })
            .catch((e) => {
              dispatch({
                type: `${typePrefix}_RELATED_ERROR`,
                error: e,
              });
            });
        };
      },
      [`${functionPrefix}Update`]: (payload) =>
        this.createAction({
          type: `${typePrefix}_UPDATE`,
          payload: payload,
          effectIfLoggedIn: {
            url: `${this.source}/${payload.id}?t=${Date.now()}`,
            method: "PATCH",
          },
        }),
      [`${functionPrefix}BulkSubmit`]: (payload) => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState(),
            { auth } = state;
          if (!isRealUser(auth)) {
            dispatch({
              type: `${typePrefix}_BULK_SUBMITERROR`,
              error: new Error("Not logged in."),
            });
            return;
          }
          dispatch({
            type: `${typePrefix}_BULK_SUBMITTING`,
          });
          return fetch(`bulkupdate${cls.source}submitted/?t=${Date.now()}`, {
            method: "POST",
            body: JSON.stringify(cls.toRequest(payload)),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Token " + auth.user.auth_token,
            },
          })
            .then((result) => result.json())
            .then((data) => {
              if (data.list) {
                data = data.list;
              }
              // Data comes back as an object - needs to be an array?
              if (!(data instanceof Object)) {
                throw new Error(data.detail || data);
              }
              return data;
            })
            .then((data) => {
              dispatch({
                type: `${typePrefix}_BULK_SUBMITTED`,
                payload: data,
              });
            })
            .catch((e) =>
              dispatch({
                type: `${typePrefix}_BULK_SUBMITERROR`,
                error: e,
              })
            );
        };
      },
      [`${functionPrefix}BulkDelete`]: (payload) => {
        var cls = this;
        return function (dispatch, getState) {
          const state = getState(),
            { auth } = state;
          if (!isRealUser(auth)) {
            dispatch({
              type: `${typePrefix}_BULK_DELETEERROR`,
              error: new Error("Not logged in."),
            });
            return;
          }
          dispatch({
            type: `${typePrefix}_BULK_DELETING`,
          });
          return fetch(`bulkupdate${cls.source}delete/?t=${Date.now()}`, {
            method: "POST",
            body: JSON.stringify(cls.toRequest(payload)),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Token " + auth.user.auth_token,
            },
          })
            .then((result) => result.json())
            .then((data) => {
              if (data.list) {
                data = data.list;
              }
              // Data comes back as an object - needs to be an array?
              if (!(data instanceof Object)) {
                throw new Error(data.detail || data);
              }
              return data;
            })
            .then((data) => {
              dispatch({
                type: `${typePrefix}_BULK_DELETED`,
                payload: data,
              });
            })
            .catch((e) =>
              dispatch({
                type: `${typePrefix}_BULK_DELETEERROR`,
                error: e,
              })
            );
        };
      },
      [`${functionPrefix}UpdateLocalOnly`]: (payload) => ({
        type: `${typePrefix}_UPDATE`,
        payload: payload,
      }),
      [`${functionPrefix}LogError`]: (payload) =>
        this.createAction({
          type: `${typePrefix}_LOG_ERROR`,
          payload: payload,
          effectIfLoggedIn: {
            url: `/logs/metrics`,
            method: "POST",
          },
        }),
      [`${functionPrefix}Delete`]: (objId) =>
        this.createAction({
          type: `${typePrefix}_DELETE`,
          payload: { id: objId },
          effectIfLoggedIn: {
            url: `${this.source}/${objId}?&t=${Date.now()}`,
            method: "DELETE",
          },
        }),
      ...baseActions,
    };
  }

  static reducer(action, cls) {
    const prefix = `ORM_${cls.modelName.toUpperCase()}`,
      errorPattern = new RegExp(`^${prefix}_([^_]+)_PUSHERROR$`),
      { payload, meta } = action,
      objId = (payload && payload.id) || (meta && meta.objectId);
    switch (action.type) {
      case `${prefix}_CREATE`:
        cls.create(payload || {});
        break;
      case `${prefix}_UPDATE`:
      case `${prefix}_CREATE_PUSHED`:
      case `${prefix}_UPDATE_PUSHED`:
        if (!cls.idExists(objId)) {
          break;
        }
        cls.withId(objId).update(action.payload);
        break;
      case `${prefix}_BULK_SUBMITTED`:
        action.payload.map((o) => {
          if (o.id && cls.idExists(o.id)) {
            cls.withId(o.id).update(o);
          }
        });
        break;
      case `${prefix}_BULK_DELETED`:
        action.payload.map((o) => {
          if (o.id && cls.idExists(o.id)) {
            cls.withId(o.id).delete();
          }
        });
        break;
      case `${prefix}_DETAIL_LOADED`:
        if (!cls.idExists(objId)) {
          break;
        }
        action.payload.synced = true;
        cls.withId(objId).update(action.payload);
        break;
      case `${prefix}_RELATED_LOADED`:
        if (!cls.idExists(objId)) {
          break;
        }
        action.payload.synced = true;
        cls.withId(objId).update(action.payload);
        break;
      case `${prefix}_CREATE_PUSHERROR`:
      case `${prefix}_UPDATE_PUSHERROR`:
      case `${prefix}_RELATED_ERROR`:
      case `${prefix}_DETAIL_ERROR`:
        if (!cls.idExists(objId)) {
          break;
        }
        cls.withId(objId).update({
          serverError: payload.response || payload.status,
        });
        break;
      case `${prefix}_DELETE`:
        if (!cls.idExists(objId)) {
          break;
        }
        cls.withId(objId).delete();
        break;
      default:
        if (action.type.match(errorPattern)) {
          console.warn(action);
        } else {
          super.reducer(action, cls);
        }
    }
  }

  _onDelete() {
    const virtualFields = this.getClass().virtualFields;
    for (const key in virtualFields) {
      // eslint-disable-line
      if (this[key] !== null) {
        const relatedQs = this[key];
        if (relatedQs.exists()) {
          relatedQs.delete();
        }
      }
    }
  }
}

// reloadAll is called in a variety of places - most noticeably if a user first hits the main pages this is called, and then called again when they log in.
// This creates a backload of requests which can take 10-20 or more seconds to clear out.
// To help alleviate this only call every model once during the initial page load in index.js.
// But everywhere else we don't need to reload the ReadOnlyModels (!isUserData) since they never change.
// This should cut down the number of requests to the server.
export function reloadAll(reloadLookups) {
  return function (dispatch) {
    orm.registry.forEach((model) => {
      if (model.isUserData && model.initialLoad) {
        const fn = model.actions[`orm${model.modelName}Reload`];
        dispatch(fn());
      }
    });
    if (reloadLookups) {
      orm.registry.forEach((model) => {
        if (!model.isUserData && model.initialLoad) {
          const fn = model.actions[`orm${model.modelName}Reload`];
          dispatch(fn());
        }
      });
    }
  };
}

export function syncReducer(state = {}, action) {
  let { type } = action;
  if (type.match(/GUESTDATA$/)) {
    return {
      ready: true,
      progress: 12,
      total: 12,
      pending: {},
      error: {},
    };
  }
  if (action.meta && action.meta.offline) {
    type = `${type}_PUSHING`;
  }
  const pushPattern = /^ORM_([^_]+)_([^_]+)_(PUSH[^_]+)$/;
  const pullPattern = /^ORM_([^_]+)_((PULL[^_]+))$/;
  const detailPattern = /^ORM_([^_]+)_(DETAIL)_([^_]+)$/;
  const relatedPattern = /^ORM_([^_]+)_(RELATED)_([^_]+)$/;
  var match =
    type.match(pushPattern) ||
    type.match(pullPattern) ||
    type.match(detailPattern) ||
    type.match(relatedPattern);
  if (!match) {
    return state;
  }

  let pending = {},
    error = {};
  let [, modelName, actionName, statusName] = match;
  let total = 0;
  orm.registry.forEach((model) => {
    if (!model.isUserData) {
      return;
    }
    total += 1;
    if (model.modelName.toUpperCase() === modelName) {
      modelName = model.modelName;
    } else if (state.error && state.error[model.modelName]) {
      error[model.modelName] = state.error[model.modelName];
    } else if (state.pending && state.pending[model.modelName]) {
      pending[model.modelName] = state.pending[model.modelName];
    }
  });

  switch (statusName) {
    case "PUSHING":
    case "PULLING":
    case "LOADING":
      pending[modelName] = actionName;
      break;
    case "PUSHED":
    case "PULLED":
    case "LOADED":
      break;
    case "PUSHERROR":
    case "PULLERROR":
    case "ERROR":
      error[modelName] = actionName;
      break;
    default:
      break;
  }
  const pendingCount = Object.keys(pending).length;
  let progress, ready;
  if (pendingCount > 0) {
    progress = total - pendingCount;
    ready = false;
  } else {
    progress = total;
    ready = true;
  }
  return {
    ready,
    progress,
    total,
    pending,
    error,
  };
}
