import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import AppContainer from "../common/AppContainer";
import EnhancedTableHead from "../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../common/Paginator";
import BreadcrumbNav from "../common/BreadCrumb";
import TableCell from "../common/TableCell";
import NumberFormat from "react-number-format";
import Icon from "@mdi/react";
import { mdiEye } from "@mdi/js";
import classNames from "classnames";

const historicWaterReporting = createSelector((session, options) => {
  let gsp = session.GSP.all().toRefArray();
  let wy = session.WaterYear.all().toRefArray();
  let gws =
    gsp && wy
      ? session.SurfaceWaterSupply.filter((r) => r.submitted == true)
          .toRefArray()
          .map((water) => {
            return {
              sws_id: water.id,
              ...water,
              ...wy.find((w) => w.id === water.water_year_id),
              ...gsp.find((g) => g.id === water.gsp),
            };
          })
      : [];
  if (gws.length) {
    console.log(gws);
  }

  gws.sort(function (x, y) {
    return y.water_year - x.water_year;
  });

  return gws;
});

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  expansionBorder: {
    borderTop: "1px solid " + theme.palette.primary.light,
    borderBottom: "1px solid " + theme.palette.primary.light,
  },

  root: {
    width: "100%",
    overflowX: "auto",
  },
  table: {
    minWidth: 500,
    "& tbody tr:nth-child(even)": {
      backgroundColor: "rgb(106,225,255,.2)",
    },
  },
  centerAlign: {
    textAlign: "center",
  },
  addYear: {
    border: "3px solid " + theme.palette.primary.main,
  },
});
class GridViewSurfaceWaterSupply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: "desc",
      orderBy: "water_year",
      page: 0,
      rowsPerPage: 10,
      histdata: [],
    };
  }
  componentDidMount() {}
  columnData() {
    const groundwaterStorageCols = [
      {
        id: "gsp_label",
        numeric: false,
        label: "GSP Organization Name",
        allowSort: true,
      },
      { id: "water_year", numeric: true, label: "Year", allowSort: true },
      { id: "details", numeric: true, label: "Details", allowSort: false },
      { id: "sw_gsp_total", numeric: true, label: "Total", allowSort: true },
      {
        id: "sw_gsp_import_cv_total",
        numeric: true,
        label: "Imported - Central Valley Project",
        allowSort: true,
      },
      {
        id: "sw_gsp_import_sw_total",
        numeric: true,
        label: "Imported - State Water Project",
        allowSort: true,
      },
      {
        id: "sw_gsp_import_l_total",
        numeric: true,
        label: "Imported - Local Imported Supplies",
        allowSort: true,
      },
      {
        id: "sw_gsp_riv_total",
        numeric: true,
        label: "Local Supplies",
        allowSort: true,
      },
      // {
      //   id: "sw_gsp_riv_total",
      //   numeric: true,
      //   label: "Local Supplies - River",
      //   allowSort: true,
      // },
      // {
      //   id: "sw_gsp_creek_total",
      //   numeric: true,
      //   label: "Local Supplies - Creek",
      //   allowSort: true,
      // },
      {
        id: "sw_gsp_recycled_total",
        numeric: true,
        label: "Recycled Water",
        allowSort: true,
      },
      {
        id: "sw_gsp_desalination_total",
        numeric: true,
        label: "Desalination",
        allowSort: true,
      },
      {
        id: "sw_gsp_other_total",
        numeric: true,
        label: "Other",
        allowSort: true,
      },
    ];
    return groundwaterStorageCols;
  }
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const colType = this.props.match.params["id"];
    const columnData = this.columnData(colType);
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.state.histdata.sort(function (a, b) {
      var numeric = columnData.find((cD) => cD.id === orderBy).numeric;
      if (numeric) {
        if (order === "desc")
          return parseFloat(b[orderBy]) < parseFloat(a[orderBy]) ? -1 : 1;
        else return parseFloat(a[orderBy]) < parseFloat(b[orderBy]) ? -1 : 1;
      } else {
        if (order === "desc")
          return (b[orderBy] || "").toUpperCase() <
            (a[orderBy] || "").toUpperCase()
            ? -1
            : 1;
        else
          return (a[orderBy] || "").toUpperCase() <
            (b[orderBy] || "").toUpperCase()
            ? -1
            : 1;
      }
    });
    this.setState({ order, orderBy });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };
  render() {
    const { classes, waterhist, authState } = this.props;
    const { page, rowsPerPage, order, orderBy, histdata } = this.state;
    if (waterhist && waterhist.length) {
      if (!histdata.length) {
        this.setState({
          histdata: waterhist.sort(function (x, y) {
            return y.water_year - x.water_year;
          }),
        });
      }
    }
    let level1 = "Surface Water Supply";
    let level1link = "/surfacewatersupply";
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav level1={level1} level1link={level1link} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">
              Submitted Surface Water Supply Data
            </Typography>
          </Grid>

          {authState &&
            authState.user &&
            authState.user.role == "Administrator" && (
              <Grid item xs={12} sm={4} mg={4} lg={4} xl={4}>
                <Button
                  color="primary"
                  fullWidth
                  className={classNames(classes.addYear)}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/addsurfacewatersupplyyear",
                    })
                  }
                >
                  Add New Year
                </Button>
              </Grid>
            )}

          {histdata ? (
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <Table className={classes.table}>
                  <EnhancedTableHead
                    columnData={this.columnData()}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                  />
                  <TableBody>
                    {histdata
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{item.gsp_label}</TableCell>
                          <TableCell>{item.water_year}</TableCell>
                          <TableCell>
                            <Tooltip title={"View Data"}>
                              <Button
                                className={classes.tableCellIcon}
                                onClick={() =>
                                  this.props.history.push({
                                    pathname:
                                      "/reporting/swsupply/" +
                                      item.id +
                                      "/" +
                                      item.sws_id,
                                  })
                                }
                              >
                                <Icon
                                  path={mdiEye}
                                  size={1}
                                  color="primary"
                                ></Icon>{" "}
                              </Button>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_import_cv_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_import_sw_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_import_l_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_riv_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          {/* <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_creek_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell> */}
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_recycled_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_desalination_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberFormat
                              value={item.sw_gsp_other_total}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={0}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    {histdata.length < 1 && (
                      <TableRow>
                        <TableCell colSpan={7} className={classes.centerAlign}>
                          No Historical Data Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {histdata.length > rowsPerPage && (
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          colSpan={7}
                          count={histdata.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActionsWrapped}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Paper>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </AppContainer>
    );
  }
}
GridViewSurfaceWaterSupply = connect(
  (state, ownProps) => ({
    waterhist: historicWaterReporting(state, ownProps),
    authState: state.auth,
  }),
  {}
)(GridViewSurfaceWaterSupply);
export default withStyles(styles)(GridViewSurfaceWaterSupply);
