import React, { Component } from "react";
import { connect } from "react-redux";
import { createSelector } from "../common/orm";
import { withStyles } from "@material-ui/core/styles";
import { Form } from "react-form";

import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Select from "../common/Select";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import NotReady from "../common/NotReady";
import ChangeInStorageDetails from "../../api/changeInStorageHOC";

import { SurfaceWaterSupply, WaterYear } from "../administration/models";

const allWaterYears = WaterYear.selectAll();

const historicWaterReporting = createSelector((session, options) => {
  let gsp = session.GSP.all().toRefArray();
  let wy = session.WaterYear.all().toRefArray();
  let gws =
    gsp && wy
      ? session.SurfaceWaterSupply.filter((r) => r.submitted == true)
          .toRefArray()
          .map((water) => {
            return {
              sws_id: water.id,
              ...water,
              ...wy.find((w) => w.id === water.water_year_id),
              ...gsp.find((g) => g.id === water.gsp),
            };
          })
      : [];
  if (gws.length) {
    console.log(gws);
  }

  gws.sort(function (x, y) {
    return y.water_year - x.water_year;
  });

  return gws;
});

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
  addYear: {
    border: "3px solid " + theme.palette.primary.main,
  },
});

class AddGroundwaterStorageYear extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      histdata: [],
      measurements: [],
    };
  }
  componentDidMount() {}

  createNewYear = (values) => {
    console.log(values);

    const { ormSurfaceWaterSupplyCreate } = this.props;

    ormSurfaceWaterSupplyCreate({
      gsp_id: 11,
      water_year_id: values.water_year_id,
      submitted: true,

      // Default Values
      sw_agg_import_cv: 0,
      sw_agg_import_sw: 0,
      sw_agg_import_l: 0,
      sw_agg_recycled: 0,
      sw_agg_desalination: 0,
      sw_agg_other: 0,
      sw_agg_riv: 0,
      sw_agg_creek: 0,
      sw_agg_total: 0,
      sw_dom_import_cv: 0,
      sw_dom_import_sw: 0,
      sw_dom_import_l: 0,
      sw_dom_recycled: 0,
      sw_dom_desalination: 0,
      sw_dom_other: 0,
      sw_dom_riv: 0,
      sw_dom_creek: 0,
      sw_dom_total: 0,
      sw_ind_import_cv: 0,
      sw_ind_import_sw: 0,
      sw_ind_import_l: 0,
      sw_ind_recycled: 0,
      sw_ind_desalination: 0,
      sw_ind_other: 0,
      sw_ind_riv: 0,
      sw_ind_creek: 0,
      sw_ind_total: 0,
      sw_wet_import_cv: 0,
      sw_wet_import_sw: 0,
      sw_wet_import_l: 0,
      sw_wet_recycled: 0,
      sw_wet_desalination: 0,
      sw_wet_other: 0,
      sw_wet_riv: 0,
      sw_wet_creek: 0,
      sw_wet_total: 0,
      sw_out_import_cv: 0,
      sw_out_import_sw: 0,
      sw_out_import_l: 0,
      sw_out_recycled: 0,
      sw_out_desalination: 0,
      sw_out_other: 0,
      sw_out_riv: 0,
      sw_out_creek: 0,
      sw_out_total: 0,
      sw_veg_import_cv: 0,
      sw_veg_import_sw: 0,
      sw_veg_import_l: 0,
      sw_veg_recycled: 0,
      sw_veg_desalination: 0,
      sw_veg_other: 0,
      sw_veg_riv: 0,
      sw_veg_creek: 0,
      sw_veg_total: 0,
      sw_other_import_cv: 0,
      sw_other_import_sw: 0,
      sw_other_import_l: 0,
      sw_other_recycled: 0,
      sw_other_desalination: 0,
      sw_other_other: 0,
      sw_other_riv: 0,
      sw_other_creek: 0,
      sw_other_total: 0,
      sw_gsp_import_cv_total: 0,
      sw_gsp_import_sw_total: 0,
      sw_gsp_import_l_total: 0,
      sw_gsp_riv_total: 0,
      sw_gsp_creek_total: 0,
      sw_gsp_recycled_total: 0,
      sw_gsp_desalination_total: 0,
      sw_gsp_other_total: 0,
      sw_gsp_total: 0,
    }).then((data) => {
      if (Number.isFinite(data)) {
        this.setState({
          moveDialogOpen: false,
        });
        this.props.history.push({
          pathname: "/reporting/swsupply/11/" + data,
        });
      }
    });
  };

  MAKE_OPTIONS_WATERYEARS = (table) => {
    return table.map((row) => ({
      label: row.water_year,
      value: row.id,
    }));
  };

  errorValidator = (values) => {
    return {
      water_year_id: values.water_year_id ? null : "Required",
    };
  };

  handleAPIChange(data) {
    this.setState({ filtered_measurements: data });
  }

  render() {
    const { classes, waterhist, wateryears } = this.props;
    const { filter, histdata, buttonState, filtered_measurements } = this.state;
    if (waterhist && waterhist.length) {
      if (!histdata.length) {
        this.setState({ histdata: waterhist });
      }
    }

    var filtered_data;
    if (filter) {
      filtered_data = this.filterByValue(histdata, filter);
    } else {
      filtered_data = histdata;
    }

    const currently_used_water_years = filtered_data.map((f) => {
      return f.water_year_id;
    });

    var filtered_water_years = wateryears
      .filter((w) => !currently_used_water_years.includes(w.id))
      .sort(function (x, y) {
        return y.water_year - x.water_year;
      });

    if (filtered_measurements === undefined) {
      return (
        <AppContainer authenticated>
          <ChangeInStorageDetails
            apitarget={"annualchange"}
            handler={this.handleAPIChange.bind(this)}
          />
          <NotReady message="Loading..." />
        </AppContainer>
      );
    } else {
      return (
        <AppContainer authenticated>
          <Grid
            container
            spacing={24}
            className={
              buttonState === "chart" ? classes.mapHeight : classes.gridHeight
            }
          >
            <Grid item xs={12} className={classes.breadCrumb}>
              <BreadcrumbNav
                level1="Submitted Surface Water Supply Data"
                level1link="/surfacewatersupply"
                level2="Add Year"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">
                Add Surface Water Supply Year
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Form
                getApi={(el) => (this.formApi = el)}
                dontValidateOnMount={true}
                validateOnSubmit={true}
                validateError={(values) => this.errorValidator(values, false)}
                onChange={this.onChange}
                onSubmit={(values) => this.createNewYear(values)}
                onSubmitFailure={(errors, formApi) => {}}
              >
                {(formApi) => (
                  <form onSubmit={formApi.submitForm}>
                    <Grid container spacing={24}>
                      <Grid item xs={12}>
                        <Typography variant="h6">
                          Please select a year for new Surface Water Supply
                          data.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={10} md={6} lg={4}>
                        <Select
                          label="Select Year"
                          field="water_year_id"
                          options={this.MAKE_OPTIONS_WATERYEARS(
                            filtered_water_years
                          )}
                          fullWidth
                        />
                      </Grid>

                      <Grid item xs={0} sm={2} md={6} lg={8}></Grid>

                      <Grid item xs={12} sm={5} md={3} lg={2}>
                        <Button
                          type="submit"
                          color="primary"
                          fullWidth
                          className={classNames(classes.addYear)}
                          onClick={() =>
                            this.setState({ moveDialogOpen: false })
                          }
                        >
                          Add Year
                        </Button>
                      </Grid>

                      <Grid item xs={12} sm={5} md={3} lg={2}>
                        <Button
                          color="primary"
                          fullWidth
                          className={classNames(classes.addYear)}
                          onClick={() =>
                            this.props.history.push({
                              pathname: "/surfacewatersupply",
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
            </Grid>
          </Grid>
        </AppContainer>
      );
    }
  }
}
AddGroundwaterStorageYear = connect(
  (state, ownProps) => ({
    wateryears: allWaterYears(state),
    waterhist: historicWaterReporting(state, ownProps),
    authState: state.auth,
  }),
  {
    ...SurfaceWaterSupply.actions,
  }
)(AddGroundwaterStorageYear);
export default withStyles(styles)(AddGroundwaterStorageYear);
