import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Form } from "react-form";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";

import AppContainer from "../common/AppContainer";
import Select from "../common/Select";
import states from "../common/states.json";
import Submit from "../common/Submit";
import TextField from "../common/TextField";
import HelpDialog from "../common/HelpDialog";

import * as authActions from "../auth/actions";

const styles = {
  registerError: {
    color: "red",
    textAlign: "center",
    marginTop: 8,
    marginBottom: -8,
  },
  linkColor: {
    color: "#2b6dad",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "#F9EBC8",
    },
  },
  marginTop: {
    marginTop: 80,
  },
};

class RegisterPage extends Component {
  state = {
    helpOpen: false,
    helpEmail: null,
  };

  errorValidator = (values) => {
    const validateName = (name) => {
      return !name ? "Field is required" : null;
    };
    const validateEmail = (email) => {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(email) ? "Invalid email address" : null;
    };
    const validatePassword = (password, password2) => {
      if (password !== password2) return "Passwords do not match";
      else if (!password || password.length < 8) return "Invalid password";
      else return null;
    };
    return {
      first_name: validateName(values.first_name),
      last_name: validateName(values.last_name),
      phone: validateName(values.phone),
      email: validateEmail(values.email),
      password: validatePassword(values.password, values.password2),
      password2: validatePassword(values.password, values.password2),
    };
  };

  registerSubmit = (values) => {
    values.email = values.email.toLowerCase();
    this.props.authRegister(values).then(() => {
      if (this.props.register.success) {
        this.setState({ helpOpen: true, helpEmail: values.email });
      }
    });
  };

  render() {
    const { classes, register, history } = this.props;
    const { helpOpen, helpEmail } = this.state;

    return (
      <AppContainer>
        <Form
          dontValidateOnMount="true"
          validateOnSubmit="true"
          validateError={this.errorValidator}
          onSubmit={this.registerSubmit}
        >
          {(formApi) => (
            <form onSubmit={formApi.submitForm}>
              <Grid container justify="center" spacing={40}>
                <Grid item xs={11} md={8} lg={6}>
                  <Typography variant="h6" className={classes.marginTop}>
                    User Registration
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={40} gutterBottom>
                <Grid item xs={11} md={8} lg={6}>
                  <Grid container justify="center" spacing={40} gutterBottom>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        field="email"
                        label="Email (this is your user name)*"
                        fullWidth
                        autoFocus
                      />
                      <TextField
                        field="first_name"
                        label="First Name*"
                        fullWidth
                      />
                      <TextField
                        field="last_name"
                        label="Last Name*"
                        fullWidth
                      />
                      <TextField
                        field="password"
                        type="password"
                        label="Password*"
                        fullWidth
                      />
                      <TextField
                        field="password2"
                        type="password"
                        label="Retype Password*"
                        fullWidth
                      />
                      <Typography>
                        Password Requirements:
                        <ul>
                          <li>Minimum 8 characters</li>
                          <li>Cannot be part of your name or email</li>
                          <li>Cannot be entirely numeric</li>
                        </ul>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField field="address" label="Address" fullWidth />
                      <TextField field="address2" label="Address 2" fullWidth />
                      <TextField field="city" label="City" fullWidth />
                      <Select
                        field="state"
                        label="State"
                        options={states}
                        fullWidth
                      />
                      <TextField
                        field="zip"
                        label="Zip Code"
                        placeholder="55555"
                        fullWidth
                      />
                      <TextField
                        field="phone"
                        label="Telephone*"
                        placeholder="555-555-5555"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={40}>
                <Grid item xs={11} md={8} lg={6}>
                  <Submit
                    label="Register"
                    altAction="/"
                    altLabel="Return to Home Page"
                    loading={register.pending}
                  />
                  <Typography className={classes.registerError}>
                    {register.error}
                  </Typography>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
        <HelpDialog
          open={helpOpen}
          onClose={() => history.push("/")}
          question="Account Registered"
          text={
            <div>
              Your account has been successfully registered in the San Gorgonio
              Pass DMS system. Please wait for an administrator to confirm your
              account. Your user name is <b>{helpEmail}</b>.
            </div>
          }
        />
      </AppContainer>
    );
  }
}

RegisterPage = connect(
  (state) => ({ register: (state.auth && state.auth.register) || {} }),
  authActions
)(RegisterPage);

export default withStyles(styles)(withRouter(RegisterPage));
