import React from "react";
import { User, Agency } from "./models";
import { connect } from "react-redux";
import { Form } from "react-form";
import { withRouter } from "react-router-dom";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import BreadcrumbNav from "../common/BreadCrumb";
import Select from "../common/Select";
import states from "../common/states.json";
import TextField from "../common/TextField";
import Icon from "@mdi/react";
import { mdiUndo } from "@mdi/js";

import updateIsStaff from "../../api/updateIsStaff";

const getUser = User.selectByUrlId();
const getAgencies = Agency.selectAll("name");

const styles = (theme) => ({
  breadCrumb: {
    width: "100vw",
    margin: "0 -20px",
    padding: "0px 35px !important",
    maxWidth: "105%",
    flexBasis: "auto",
    backgroundColor: "#eeeeee",
  },
});

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    this.is_new = false;
    if (props.match.params["id"] === "new") this.is_new = true;
  }

  componentDidMount() {
    const { authState, history } = this.props;
    if (
      authState &&
      authState.user &&
      authState.user.role !== "Administrator"
    ) {
      history.push("/dashboard");
    }
  }

  MAKE_OPTIONS = (table) => {
    return table.map((row) => ({
      label: row.name,
      value: row.id,
    }));
  };

  errorValidator = (values) => {
    const validateEmail = (email) => {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !re.test(email) ? "Invalid Email Address" : null;
    };
    const validateName = (name) => {
      return !name ? "Field is required" : null;
    };
    const validatePassword = (password, password2) => {
      if (password !== password2) return "Passwords do not match";
      else if (!password || password.length < 8) return "Invalid password";
      else return null;
    };
    var valObj = {};
    if (this.is_new) {
      valObj["password"] = validatePassword(values.password, values.password2);
      valObj["password2"] = validatePassword(values.password, values.password2);
    }
    valObj["email"] = validateEmail(values.email);
    valObj["first_name"] = validateName(values.first_name);
    valObj["last_name"] = validateName(values.last_name);
    valObj["phone"] = validateName(values.phone);
    valObj["agency"] = validateName(values.agency);
    return valObj;
  };

  submitForm = (values) => {
    const { user, authState, ormUserCreate, ormUserUpdate, match, history } =
      this.props;
    let token = authState && authState.user ? authState.user.auth_token : "";
    if (match.params["id"] === "new") {
      var createPromise = ormUserCreate(values);
      Promise.all([createPromise]).then(function (returnValues) {
        let newUserId = returnValues[0];
        // is_staff is false by default so only set to true if role for new user is Administrator
        if (values["role"] == "Administrator") {
          updateIsStaff(token, newUserId, true);
        }
        history.push("/adminusers");
      });
    } else {
      var updatePromise = ormUserUpdate({ id: user.id, ...values });
      Promise.all([updatePromise]).then(function (returnValues) {
        let is_staff = values["role"] == "Administrator" ? true : false;
        updateIsStaff(token, user.id, is_staff);
        history.push("/adminusers");
      });
    }
  };

  render() {
    const { user, classes, history, match, agencies } = this.props;
    return (
      <AppContainer authenticated>
        <Grid container spacing={24}>
          <Grid item xs={12} className={classes.breadCrumb}>
            <BreadcrumbNav
              level1="Administration"
              level1link="/administration"
              level2="Manage Users"
              level2link="/adminusers"
              level3={(this.is_new ? "Add" : "Edit") + " User"}
              level3link={"/adminusers/" + match.params["id"]}
            />
          </Grid>
          <Grid item xs={12} mg={9} lg={6} xl={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => history.push("/adminusers")}
            >
              <Icon path={mdiUndo} size={1} color="white"></Icon>
              &nbsp;&nbsp;&nbsp; Return to Manager Users
            </Button>
          </Grid>
          <Form
            dontValidateOnMount="true"
            validateOnSubmit="true"
            defaultValues={user}
            validateError={this.errorValidator}
            onSubmit={this.submitForm}
          >
            {(formApi) => (
              <Grid item xs={12}>
                <form onSubmit={formApi.submitForm}>
                  <Grid container spacing={40}>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField
                        field="email"
                        label="Email (username)"
                        fullWidth
                      />
                      <TextField
                        field="first_name"
                        label="First Name*"
                        fullWidth
                      />
                      <TextField
                        field="last_name"
                        label="Last Name*"
                        fullWidth
                      />
                      <TextField
                        field="phone"
                        label="Telephone*"
                        placeholder="555-555-5555"
                        fullWidth
                      />
                      <TextField field="address" label="Address" fullWidth />
                      <TextField field="address2" label="Address 2" fullWidth />
                      <Select
                        field="status"
                        label="Status"
                        options={[
                          { label: "Active", value: "Active" },
                          { label: "Pending", value: "Pending" },
                          { label: "Inactive", value: "Inactive" },
                        ]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={10} sm={6} md={4} lg={3}>
                      <TextField field="city" label="City" fullWidth />
                      <Select
                        field="state"
                        label="State"
                        options={states}
                        fullWidth
                      />
                      <TextField
                        field="zip"
                        label="Zip Code"
                        placeholder="55555"
                        fullWidth
                      />
                      <Select
                        field="role"
                        label="Role"
                        options={[
                          { label: "Agency", value: "Agency" },
                          {
                            label: "GSA Representative",
                            value: "GSA Representative",
                          },
                          { label: "Administrator", value: "Administrator" },
                        ]}
                        fullWidth
                      />
                      <Select
                        field="agency"
                        label="Agency*"
                        options={this.MAKE_OPTIONS(agencies)}
                        fullWidth
                      />
                      {this.is_new && (
                        <>
                          <TextField
                            type="password"
                            field="password"
                            label="Password*"
                            fullWidth
                          />
                          <TextField
                            type="password"
                            field="password2"
                            label="Confirm Password*"
                            fullWidth
                          />
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={40}>
                    <Grid item xs={12} mg={9} lg={6} xl={3}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        color="primary"
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={12} mg={9} lg={6} xl={3}>
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={() =>
                          history.push(
                            "/adminusers/" +
                              match.params["id"] +
                              "/resetpassword"
                          )
                        }
                      >
                        Reset Password
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            )}
          </Form>
        </Grid>
      </AppContainer>
    );
  }
}

EditUser = connect(
  (state, ownProps) => ({
    user: getUser(state, ownProps),
    agencies: getAgencies(state, ownProps),
    authState: state.auth,
  }),
  {
    ...User.actions,
  }
)(EditUser);

export default withStyles(styles)(withRouter(EditUser));
