const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

const LOCAL_URL = "http://localhost:3000";
const PROD_URL = "https://sgp-dms.houstoneng.net";

export default function setUserIsStaff(token, uid, authorized_reporter, gsp) {
  let URL = isLocalhost ? LOCAL_URL : PROD_URL;
  fetch(URL + "/updateauthreporter/" + uid, {
    method: "POST",
    body: JSON.stringify({
      authorized_reporter: authorized_reporter,
      gsp: gsp,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Token " + token,
    },
  });
}
