import React from "react";
import { withRouter } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import classNames from "classnames";

const styles = (theme) => ({
  paper: {
    backgroundColor: "#fff",
    float: "left",
    padding: "8px 31px",
    margin: "15px -3px",
    borderBottom: "3px solid " + theme.palette.primary.main,
    borderTop: "3px solid " + theme.palette.primary.main,
    borderLeft: "3px solid " + theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#eee",
    },
    "&:hover + div": {
      backgroundColor: "#eee",
    },
    cursor: "pointer",
    position: "relative",
    color: "black",
    textDecoration: "none",
    fontWeight: "400",
  },
  paper2: {
    padding: "8px 36px 8px 46px",
    borderLeft: 0,
  },
  chevron: {
    padding: "2px 10px",
    float: "left",
    margin: "20px -12px",
    width: "10px",
    height: "26px",
    borderRight: "3px solid " + theme.palette.primary.main,
    borderBottom: "3px solid " + theme.palette.primary.main,
    transform: "rotate(-45deg)",
    boxShadow: "none",
    position: "relative",
  },
});

const BreadcrumbNav = ({
  level1,
  level1link,
  level2 = null,
  level2link,
  level3 = null,
  level3link,
  level4 = null,
  level4link,
  classes = this.props,
  history = this.props,
}) => (
  <div>
    <Typography
      className={classes.paper}
      style={{ zIndex: 9 }}
      onClick={() => history.push(level1link)}
    >
      {level1}
    </Typography>
    <Paper className={classes.chevron} style={{ zIndex: 8 }}></Paper>
    {level2 && (
      <>
        <Typography
          className={classNames(classes.paper, classes.paper2)}
          style={{ zIndex: 7 }}
          onClick={() => history.push(level2link)}
        >
          {level2}
        </Typography>
        <Paper className={classes.chevron} style={{ zIndex: 6 }}></Paper>
      </>
    )}
    {level3 && (
      <>
        <Typography
          className={classNames(classes.paper, classes.paper2)}
          style={{ zIndex: 5 }}
          onClick={() => history.push(level3link)}
        >
          {level3}
        </Typography>
        <Paper className={classes.chevron} style={{ zIndex: 4 }}></Paper>
      </>
    )}
    {level4 && (
      <>
        <Typography
          className={classNames(classes.paper, classes.paper2)}
          style={{ zIndex: 3 }}
          onClick={() => history.push(level4link)}
        >
          {level4}
        </Typography>
        <Paper className={classes.chevron} style={{ zIndex: 2 }}></Paper>
      </>
    )}
  </div>
);

export default withStyles(styles)(withRouter(BreadcrumbNav));
