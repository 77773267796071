import React from "react";
import CollapsibleLayersControl from "./CollapsibleLayersControl";
import { Map, Marker, TileLayer, WMSTileLayer } from "react-leaflet";
import * as app_settings from "../../../app_settings";

const colors = [
  "",
  { hex: "#CF382D", color: "red" },
  { hex: "#EF8D2F", color: "orange" },
  { hex: "#6DA42E", color: "green" },
  { hex: "#31A2CC", color: "teal" },
  { hex: "#C24EB1", color: "purple" },
  { hex: "#962E34", color: "darkred" },
  { hex: "#095B97", color: "darkblue" },
  { hex: "#717825", color: "lime" },
  { hex: "#593466", color: "darkpurple" },
  { hex: "#406470", color: "royalblue" },
  { hex: "#FE8878", color: "peach" },
  { hex: "#F8CA85", color: "yellow" },
  { hex: "#B7F272", color: "lightgreen" },
  { hex: "#8CD5FF", color: "skyblue" },
  { hex: "#FC8CE6", color: "pink" },
  { hex: "#E57C86", color: "lightred" },
  { hex: "#A4A2A5", color: "gray" },
  { hex: "#2F2D30", color: "black" },
];

class EditMap extends React.Component {
  constructor() {
    super();
    this.state = app_settings.map_initial_state;
    this.setState({ lat: 36.642, lng: -117.817, zoom: 5, collapsed: false });
  }

  render() {
    const { collapsed } = this.state;
    const { pageType } = this.props;
    const position = [
      this.props.geometry ? this.props.geometry.coordinates[1] : this.state.lat,
      this.props.geometry ? this.props.geometry.coordinates[0] : this.state.lng,
    ];

    var controlPosition = this.props.controlPosition;

    if (!controlPosition) {
      controlPosition = "topleft";
    }

    const zoom =
      this.props.geometry && !this.props.zoom
        ? 12
        : this.props.zoom
        ? this.props.zoom
        : this.state.zoom;

    return (
      <Map
        className="editmap-container"
        height={this.props.height}
        width={this.props.width}
        center={position}
        zoom={zoom}
      >
        <Marker
          draggable={"true"}
          onDragend={this.props.handler}
          position={position}
        ></Marker>
        {/* <CollapsibleLayersControl
          position="topright"
          controlPosition="topright"
          collapsed={collapsed}
        >
          <CollapsibleLayersControl.BaseLayer name="Esri Topographic">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </CollapsibleLayersControl.BaseLayer>
          <CollapsibleLayersControl.BaseLayer checked name="Esri Streets">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </CollapsibleLayersControl.BaseLayer>
          <CollapsibleLayersControl.BaseLayer name="Esri Imagery">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </CollapsibleLayersControl.BaseLayer>
        </CollapsibleLayersControl> */}

        <CollapsibleLayersControl
          position="topright"
          controlPosition={controlPosition}
          collapsed={collapsed}
        >
          {app_settings.map_layers
            .filter((l) => l.add === true && l.type === "baselayer")
            .map((l) => (
              <CollapsibleLayersControl.BaseLayer
                key={l.id}
                checked={l.checked}
                name={l.name}
              >
                <TileLayer
                  info={null}
                  attribution={l.attribution}
                  url={l.url}
                />
              </CollapsibleLayersControl.BaseLayer>
            ))}

          {/* {pageType &&
            app_settings.map_layers
              .filter(
                (l) =>
                  l.add === true &&
                  l.type === "maplayer" &&
                  l.page_type === pageType
              )
              .sort((a, b) => a.index - b.index)
              .map((l) => (
                <CollapsibleLayersControl.Overlay
                  key={l.id}
                  checked={
                    pageType ? pageType === l.page_type || !pageType : l.checked
                  }
                  name={l.title}
                >
                  <MapLayers
                    info={l}
                    features={
                      pageType === l.page_type
                        ? filtered_data
                        : features[l.type2]
                    }
                    title={l.title}
                    link={l.link}
                    clusterHex={l.color.hex}
                    clusterColor={l.color.rgb}
                    legend={[{ title: l.title, color: l.color.hex }]}
                    markerSize={l.markerSize}
                  />
                </CollapsibleLayersControl.Overlay>
              ))} */}
          {/* {pageType &&
            app_settings.map_layers
              .filter(
                (l) =>
                  l.add === true &&
                  l.type === "wmstilelayer" &&
                  l.selector === undefined
              )
              .map((l) => (
                <CollapsibleLayersControl.Overlay2
                  key={l.id}
                  checked={l.checked}
                  name={l.title}
                >
                  <WMSTileLayer
                    info={l}
                    layers={l.layers}
                    transparent={l.transparent}
                    opacity={l.opacity}
                    format={l.format}
                    map={l.map}
                    legend={l.legend}
                    url={l.url}
                  />
                </CollapsibleLayersControl.Overlay2>
              ))} */}

          {/* {pageType === undefined &&
            app_settings.map_layers
              .filter((l) => l.add === true && l.type === "maplayer")
              .sort((a, b) => a.index - b.index)
              .map((l) => (
                <CollapsibleLayersControl.Overlay
                  key={l.id}
                  checked={
                    pageType ? pageType === l.page_type || !pageType : l.checked
                  }
                  name={l.title}
                >
                  <MapLayers
                    info={l}
                    features={
                      pageType === l.page_type
                        ? filtered_data
                        : features[l.type2]
                    }
                    title={l.title}
                    link={l.link}
                    clusterHex={l.color.hex}
                    clusterColor={l.color.rgb}
                    legend={[{ title: l.title, color: l.color.hex }]}
                    markerSize={l.markerSize}
                  />
                </CollapsibleLayersControl.Overlay>
              ))} */}
          {pageType === undefined &&
            app_settings.map_layers
              .filter((l) => l.add === true && l.type === "wmstilelayer")
              .map((l) => (
                <CollapsibleLayersControl.Overlay2
                  key={l.id}
                  checked={l.checked}
                  name={l.title}
                >
                  <WMSTileLayer
                    info={l}
                    layers={l.layers}
                    transparent={l.transparent}
                    opacity={l.opacity}
                    format={l.format}
                    map={l.map}
                    legend={l.legend}
                    url={l.url}
                  />
                </CollapsibleLayersControl.Overlay2>
              ))}
        </CollapsibleLayersControl>
      </Map>
    );
  }
}

export default EditMap;
