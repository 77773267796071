import React from "react";
import {
  Map,
  Marker,
  TileLayer,
  LayersControl,
  WMSTileLayer,
} from "react-leaflet";

import MapLayers from "./MapLayers";
//import esri from 'esri-leaflet';

const { Overlay } = LayersControl;

class CreateMap extends React.Component {
  constructor() {
    super();
    this.state = {
      lat: 36.642,
      lng: -119.817,
      zoom: 13,
    };
  }
  handleZoom(lat, lng) {
    this.setState({ lat: lat });
    this.setState({ lng: lng });
    //render(); // Probably the wrong way to handle it.
  }
  markerLayers() {
    // Get all markers from /scripts/autocomplete.php
  }

  toggleDraggable = () => {
    console.log("we are dragging");
    this.setState({ draggable: !this.state.draggable });
  };

  // style={{ position: "absolute", top:"40px", left:"10px" }}
  render() {
    const position = [this.state.lat, this.state.lng];
    debugger;
    return (
      <Map center={position} zoom={this.state.zoom} maxZoom="20">
        <Marker
          draggable={this.state.draggable}
          onDragend={this.props.updateLatLng}
          position={position}
        ></Marker>
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Esri Topographic">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri Streets">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Esri Imagery">
            <TileLayer
              attribution="ESRI"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </LayersControl.BaseLayer>
          <Overlay name="Groundwater Wells">
            <MapLayers />
          </Overlay>
        </LayersControl>
      </Map>
    );
  }
}

export default CreateMap;
