import {
    SAVE_FLEXIBLE,
} from "./actions";

function saveReducer(state={}, action) {
    switch (action.type) {
        case SAVE_FLEXIBLE: {
            return state;
        }
        default:
            return state;
    }
}

export default saveReducer;