import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
const styles = {
  supportIcon: {
    width: "3em",
    height: "3em",
    color: "#fff",
    marginLeft: 24
  },
  titleContainer: {
    backgroundColor: "#b20000",
    textAlign: "center"
  },
  questionTitle: {
    marginTop: ".6em",
    color: "#b20000",
    fontWeight: 500
  },
  greyButton: {
    backgroundColor: "#808080",
    color: "#fff"
  },
  centerButtons: {
    justifyContent: "center",
    marginTop: 24
  },
  primaryButton: {
    backgroundColor: "#b20000",
    color: "#fff"
  },
  nooverflow: {
    overflow: "hidden"
  },
  closeIcon: {
    float: "right",
    color: "#fff",
    marginTop: -16,
    marginRight: -16
  }
};
const WarningDialog = ({
  title,
  text,
  classes,
  confirmAction,
  cancelAction,
  confirmText,
  cancelText,
  noActions,
  onClose,
  ...other
}) => (
  <Dialog {...other} onClose={onClose} classes={{ paper: classes.nooverflow }}>
    <DialogTitle className={classes.titleContainer}>
      <ErrorOutline className={classes.supportIcon} />
      <IconButton
        onClick={noActions ? onClose : cancelAction}
        className={classes.closeIcon}
      >
        <Close />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Typography variant="h6" className={classes.questionTitle} gutterBottom>
        {title}
      </Typography>
      <DialogContentText>{text}</DialogContentText>
      {!noActions && (
        <DialogActions className={classes.centerButtons}>
          <Button onClick={confirmAction} color="primary" variant="contained">
            {confirmText ? confirmText : "Delete Entry"}
          </Button>
          <Button
            onClick={cancelAction}
            variant="contained"
            className={classes.greyButton}
            autoFocus
          >
            {cancelText ? cancelText : "Do Not Delete"}
          </Button>
        </DialogActions>
      )}
    </DialogContent>
  </Dialog>
);
export default withStyles(styles)(WarningDialog);
