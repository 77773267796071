const map_initial_state = {
  lat: 33.96,
  lng: -116.78,
  zoom: 12,
  collapsed: false,
  showLayerList: true,
};

const map_layers = [
  // {
  //   id: "esri_topographic",
  //   add: true,
  //   checked: true,
  //   type: "baselayer",
  //   type2: "topographic",
  //   name: "ESRI Topographic",
  //   attribution: "ESRI",
  //   url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  // },
  // {
  //   id: "esri_streets",
  //   add: true,
  //   checked: false,
  //   type: "baselayer",
  //   type2: "street",
  //   name: "ESRI Streets",
  //   attribution: "ESRI",
  //   url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  // },
  // {
  //   id: "esri_reference_hybrid",
  //   add: true,
  //   checked: false,
  //   type: "baselayer",
  //   type2: "imagery",
  //   name: "ESRI Hybrid Reference",
  //   attribution: "ESRI",
  //   url: "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}",
  // },
  {
    id: "esri_imagery",
    add: true,
    checked: true,
    type: "baselayer",
    type2: "imagery",
    name: "ESRI Imagery",
    attribution: "ESRI",
    url: "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "water_level_sites",
    add: true,
    checked: true,
    title: "Water Level - Representative Monitoring Sites",
    type: "maplayer",
    type2: "water_level_sites",
    page_type: "Water Level",
    link: "waterleveldetail/",
    color: {
      hex: "#CF382D",
      rgb: "red",
    },
    markerSize: 8,
    identify: true,
    index: 0,
  },
  {
    id: "water_level_sites_other",
    add: true,
    checked: true,
    title: "Water Level - Other Monitoring Sites",
    type: "maplayer",
    type2: "water_level_sites_other",
    page_type: "Water Level",
    link: "waterleveldetail/",
    color: {
      hex: "#e59400",
      rgb: "orange",
    },
    markerSize: 8,
    identify: true,
    index: 1,
  },
  {
    id: "water_quality_sites",
    add: true,
    checked: true,
    title: "Water Quality Monitoring Sites",
    type: "maplayer",
    type2: "water_quality_sites",
    page_type: "Water Quality",
    link: "waterqualitydetail/",
    color: {
      hex: "#00FF00",
      rgb: "green",
    },
    markerSize: 8,
    identify: true,
    index: 2,
  },
  // {
  //   id: "subsidence_monitoring_sites",
  //   add: true,
  //   checked: true,
  //   title: "Subsidence Monitoring Sites",
  //   type: "maplayer",
  //   type2: "subsidence_monitoring_sites",
  //   page_type: "Subsidence",
  //   link: "subsidencedetail/",
  //   color: {
  //     hex: "#0000FF",
  //     rgb: "blue",
  //   },
  //   markerSize: 8,
  //   identify: true,
  //   index: 3,
  // },
  // {
  //   id: "surface_water_monitoring_sites",
  //   add: true,
  //   checked: true,
  //   title: "Interconnected SW Monitoring Sites",
  //   type: "maplayer",
  //   type2: "surface_water_monitoring_sites",
  //   page_type: "SWGW",
  //   link: "swgwdetail/",
  //   color: {
  //     hex: "#31A2CC",
  //     rgb: "teal",
  //   },
  //   markerSize: 8,
  //   identify: true,
  //   index: 3,
  // },
  // {
  //   id: "stream_monitoring_sites",
  //   add: true,
  //   checked: true,
  //   title: "Stream Monitoring Sites",
  //   type: "maplayer",
  //   type2: "stream_monitoring_sites",
  //   page_type: "Stream Monitoring",
  //   link: "streammonitoringdetail/",
  //   color: {
  //     hex: "#8CD5FF",
  //     rgb: "skyblue",
  //   },
  //   markerSize: 8,
  //   identify: true,
  //   index: 4,
  // },
  {
    id: "esri_transportation_reference",
    add: true,
    title: "Transportation Reference",
    checked: true,
    type: "wmstilelayer",
    type2: "imagery",
    name: "Transportation Reference",
    // legend: [{ title: "ESRI Hybrid Reference" }],
    attribution: "ESRI",
    url: "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}",
  },
  {
    id: "GAMA_Results_wm",
    add: true,
    title: "Water Quality Monitoring Sites",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      { title: "Water Quality Monitoring Sites", color: "rgb(255,255,0)" },
    ],
    layers: "GAMA_Results_wm",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 4,
  },
  {
    id: "Tribal_land",
    add: true,
    title: "Morongo Band of Mission Indians Land",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        title: "Morongo Band of Mission Indians Land",
        color: "rgb(244,109,67)",
      },
    ],
    layers: "Tribal_land",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    index: 6,
  },
  {
    id: "storage_unit",
    add: true,
    title: "Storage Units",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        title: "MA1: Adjudicated",
        color: "rgb(158,1,66)",
      },
      {
        title: "MA2: Cabazon",
        color: "rgb(213,62,79)",
      },
      {
        title: "MA3: Verbenia",
        color: "rgb(244,109,67)",
      },
      {
        title: "MA4: DWA GSA",
        color: "rgb(253,174,97)",
      },
      {
        title: "MA5: Banning Canyon",
        color: "rgb(102,194,165)",
      },
      {
        title: "MA6: Banning Bench",
        color: "rgb(50,136,189)",
      },
      {
        title: "MA7: Banning",
        color: "rgb(94,79,162)",
      },
    ],
    layers: "Storage_Unit",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 5,
  },
  {
    id: "subbasins",
    add: true,
    title: "GSA Boundaries",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [
      {
        title: "Desert Water Agency",
        color: "rgb(255,0,0)",
      },
      {
        title: "Verbenia",
        color: "rgb(0,255,0)",
      },
      {
        title: "San Gorgonio Pass",
        color: "rgb(0,0,255)",
      },
    ],
    layers: "subbasins",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 5,
  },
  {
    id: "SGP_Subbasin_GSP",
    add: true,
    title: "SGP Subbasin",
    checked: true,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "SGP Subbasin", color: "rgb(0,0,0)" }],
    layers: "SGP_Subbasin_GSP",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    index: 6,
  },
  {
    id: "san_gorgonio_pass_wa",
    add: true,
    title: "San Gorgonio Pass WA",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "San Gorgonio Pass WA", color: "rgb(110,110,110)" }],
    layers: "SanGorgonioPassWA",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 7,
  },
  {
    id: "mission_springs_wd",
    add: true,
    title: "Mission Springs WD",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Mission Springs WD", color: "#E69801" }],
    layers: "MissionSpringsWD",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 8,
  },
  {
    id: "high_valleys_wd",
    add: true,
    title: "High Valleys WD",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "High Valleys WD", color: "#01FFC5" }],
    layers: "HighValleysWD",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 9,
  },
  {
    id: "desert_wa",
    add: true,
    title: "Desert WA",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Desert WA", color: "#FF01C5" }],
    layers: "DesertWA",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 10,
  },
  {
    id: "city_of_banning_wsa",
    add: true,
    title: "City of Banning WSA",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "City of Banning WSA", color: "#476930" }],
    layers: "CityOfBanning_WSA",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 11,
  },
  {
    id: "cabazon_wd",
    add: true,
    title: "Cabazon WD",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Cabazon WD", color: "#FFFF01" }],
    layers: "CabazonWD",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 12,
  },
  {
    id: "banning_heights_wmc",
    add: true,
    title: "Banning Heights MWC",
    checked: false,
    type: "wmstilelayer",
    transparent: true,
    opacity: "1",
    format: "image/png",
    legend: [{ title: "Banning Heights MWC", color: "#E60101" }],
    layers: "BanningHeightsMWC",
    map: "/var/www/sangorgonio/maps/map.map",
    url: "https://sgp-dms.houstoneng.net/cgi-bin/mapserv",
    identify: true,
    index: 13,
  },
];

export { map_initial_state, map_layers };
