import React, { Component } from "react";
import { connect } from "react-redux";
import PageHeader from "../common/PageHeader";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import CreateMap from "../common/map/CreateMap";

const styles = theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
    top: "-14px"
  },
  MuiFormLabel: {
    top: "-15px"
  }
});

function handleSubmitfunc(event) {
  const { authState } = this.props;
  fetch("/", {
    headers: {
      Authorization: "Token " + authState.user.auth_token
    },
    method: "POST",
    body: new FormData(event.target)
  });
}

class newWellForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      item: [],
      map: true
    };
    this.handleSubmit = handleSubmitfunc.bind(this);
    if (this.state.item.volumetric === "") {
      this.warningMessage =
        "You are attemping to create a volume pump record without a Volumetric Pumping Calculation defined for the groundwater well.";
    }
  }

  toggleMaps(event) {
    var val = event.target.value;
    if (val === "Current Location") {
      // Set Lat/Lng fields unhidden
      // Set value of those to the navigator.
      this.setState({
        map: false
      });
    } else if (val === "Click Point") {
      this.setState({
        map: true
      });
    } else if (val === "Enter") {
      this.setState({
        map: false
      });
      // Hide map
    }
  }

  updateLatLng(e) {
    console.log("Updating LatLng");
    this.setState({
      lat: e.latlng.lat,
      lng: e.latlng.lng
    });
  }

  render() {
    //const { isEdit, item } = this.state;
    const { classes, authState } = this.props; // authState seems to be here by magic! :-) //navigation
    if (!authState) return "...";
    /*if (!isEdit) {
            this.setState({id: "new"});
        } */
    return (
      <AppContainer authenticated>
        <PageHeader title={" Enter New Well "} />
        <form
          onSubmit={this.handleSubmit}
          style={{ "margin-left": "20px", marginTop: "15px" }}
        >
          <InputLabel>Well ID</InputLabel>
          <TextField id="wellid" className={classes.textField} />
          <InputLabel>State Well Number</InputLabel>
          <TextField id="state_well" className={classes.textField} />
          <br />
          <InputLabel>Well Type</InputLabel>
          <Select
            inputProps={{
              name: "well_type",
              id: "well_type"
            }}
          >
            <MenuItem value="">Select One...</MenuItem>
            <MenuItem value="AG">AG</MenuItem>
            <MenuItem value="Galvanized">Galvanized</MenuItem>
            <MenuItem value="Galvanized Pipe">Galvanized Pipe</MenuItem>
            <MenuItem value="Irrigation">Irrigation</MenuItem>
            <MenuItem value="MONITOR">MONITOR</MenuItem>
            <MenuItem value="Observation">Observation</MenuItem>
            <MenuItem value="Pipe">Pipe</MenuItem>
            <MenuItem value="Residential">Residential</MenuItem>
            <MenuItem value="Stockwatering">Stockwatering</MenuItem>
            <MenuItem value="SUBMERSE AG">SUBMERSE AG</MenuItem>
            <MenuItem value="SUBMERSE DOM">SUBMERSE DOM</MenuItem>
            <MenuItem value="Unknown">Unknown</MenuItem>
          </Select>

          <InputLabel>Volumetric Pumping Calculations</InputLabel>
          <Select
            inputProps={{
              name: "volumetric",
              id: "volumetric"
            }}
          >
            <MenuItem value="">Select One...</MenuItem>
            <MenuItem value="Kw/h">Kw/h</MenuItem>
            <MenuItem value="Hours">Hours</MenuItem>
            <MenuItem value="AF">AF</MenuItem>
          </Select>

          <FormControl component="fieldset">
            <FormLabel component="legend">Enter Location Using</FormLabel>
            <RadioGroup
              onChange={this.toggleMaps.bind(this)}
              aria-label="Enter Location Using"
              name="method"
              style={{ display: "inline-block" }}
            >
              <FormControlLabel
                value="Current Location"
                control={<Radio />}
                label="Current Location (use GPS)"
              />
              <FormControlLabel
                value="Click Point"
                control={<Radio />}
                label="Click Point on Map"
              />
              <FormControlLabel
                value="Enter"
                control={<Radio />}
                label="Enter Latitude/Longitude"
              />
            </RadioGroup>
          </FormControl>
          {this.state.map ? <CreateMap handler={this.updateLatLng} /> : null}
          <br />
          {this.state.map ? null : (
            <>
              <TextField
                id="lat"
                label="Latitude"
                type="number"
                value={this.state.lng}
                className={classes.textField}
              />
              <TextField
                id="long"
                label="Longitude"
                type="number"
                value={this.state.lat}
                fullWidth
                className={classes.textField}
              />
            </>
          )}
          <br />
          <TextField
            id="notes"
            label="Notes"
            fullWidth
            className={classes.textField}
          />
          <br />
          <Button type="submit">Submit</Button>
        </form>
      </AppContainer>
    );
  }
}
newWellForm = connect(state => ({ authState: state.auth }), {
  ...authActions
})(newWellForm);

newWellForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(newWellForm);
