import React, { Component } from "react";
import { Popup } from "react-leaflet";
import CircleMarker from "./CircleMarker";

import { Marker } from "react-leaflet";

import L from "leaflet";
import MarkerClusterGroup from "./MarkerCluster";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";

import RedSquareSvg from "./red_square.svg";
import OrangeCircleSvg from "./orange_circle.svg";
import GreenTriangleSvg from "./green_triangle.svg";

const RedSquareIcon = new L.Icon({
  iconUrl: RedSquareSvg,
  iconRetinaUrl: RedSquareSvg,
  iconSize: [16, 16],
});

const OrangeCircleIcon = new L.Icon({
  iconUrl: OrangeCircleSvg,
  iconRetinaUrl: OrangeCircleSvg,
  iconSize: [16, 16],
});

const GreenTriangleIcon = new L.Icon({
  iconUrl: GreenTriangleSvg,
  iconRetinaUrl: GreenTriangleSvg,
  iconSize: [16, 16],
});

class PageLayer extends Component {
  iconCreateFunction = (cluster) => {
    return new L.divIcon({
      html: "<div><span>" + cluster.getChildCount() + "</span></div>",
      className: "marker-cluster marker-cluster-" + this.props.clusterColor,
      iconSize: new L.Point(40, 40),
    });
  };

  mapPosition(item) {
    return [item.geometry.coordinates[1], item.geometry.coordinates[0]];
  }

  render() {
    const {
      legend,
      clusterColor,
      features,
      title,
      link,
      agencies,
      markerSize,
      type2,
    } = this.props;

    return (
      <MarkerClusterGroup
        legend={legend}
        clusterColor={clusterColor}
        disableClusteringAtZoom={0}
        spiderfyOnMaxZoom={false}
        iconCreateFunction={this.iconCreateFunction}
      >
        {features.map((item) => {
          if (!item.geometry) return null;

          if (type2 === "water_level_sites") {
            return (
              <Marker
                bubblingMouseEvents={true}
                key={item.id}
                position={this.mapPosition(item)}
                icon={RedSquareIcon}
              />
            );
          } else if (type2 === "water_level_sites_other") {
            return (
              <Marker
                bubblingMouseEvents={true}
                key={item.id}
                position={this.mapPosition(item)}
                icon={OrangeCircleIcon}
              />
            );
          } else if (type2 === "water_quality_sites") {
            return (
              <Marker
                bubblingMouseEvents={true}
                key={item.id}
                position={this.mapPosition(item)}
                icon={GreenTriangleIcon}
              />
            );
          }

          // return (
          //   <CircleMarker
          //     key={item.id}
          //     color={clusterColor}
          //     radius={markerSize}
          //     position={this.mapPosition(item)}
          //     center={this.mapPosition(item)}
          //   ></CircleMarker>
          // );
        })}
      </MarkerClusterGroup>
    );
  }
}

PageLayer = connect(
  (state) => ({
    authState: state.auth,
  }),
  null
)(PageLayer);

export default withRouter(PageLayer);
