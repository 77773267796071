import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { BlueOnClick } from "../common/Button";
import { withStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AppContainer from "../common/AppContainer";
import * as authActions from "../auth/actions";
import * as navActions from "../common/actions";
import BreadcrumbNav from "../common/BreadCrumb";
import InputAdornment from "@material-ui/core/InputAdornment";
import { SurfaceWaterSupply } from "./models";
import { createSelector } from "../common/orm";
import HelpLabel from "../common/HelpLabel";
import NumberFormat from "react-number-format";
/* Calc Method Start */
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
const getOptions = SurfaceWaterSupply.getOptions();
const CalcMethod = (props) => {
  // props: fieldName, currentValue
  const [value, setValue] = React.useState(props.currentValue); // eslint-disable-line no-unused-vars
  /* TODO: Figure out how to set the field's value so that the value of the field and make sure the form can read it */
  return (
    <FormControl component="fieldset">
      <RadioGroup
        style={{ flexWrap: "nowrap", marginLeft: "10px" }}
        aria-label="direct"
        name={props.fieldName}
        row
      >
        <FormControlLabel
          value="Direct"
          disabled={props.disabled}
          onChange={props.onChange}
          style={{ alignItems: "center" }}
          control={
            <Radio
              checked={props.value === "Direct"}
              style={{
                padding: "6px 0px 3px 7px",
                color: props.value === "Direct" ? "#13aff1" : "",
              }}
            />
          }
          label="Direct"
          labelPlacement="end"
        />
        <FormControlLabel
          value="Estimated"
          disabled={props.disabled}
          onChange={props.onChange}
          style={{ alignItems: "center" }}
          control={
            <Radio
              checked={props.value === "Estimated"}
              style={{
                padding: "6px 0 3px 7px",
                color: props.value === "Estimated" ? "#13aff1" : "",
              }}
            />
          }
          label="Estimated"
          labelPlacement="end"
        />
      </RadioGroup>
    </FormControl>
  );
};
/* Calc Method End */
const getUserData = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["gsp"]),
  }),
  (session, options) => {
    let user = session.User.filter((u) => u.id === options.id).toRefArray()[0];

    let association = session.GSPAssociation.filter(
      (u) => u.user === options.id && u.gsp === options.gsp
    ).toRefArray()[0];

    let gsp = association
      ? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
      : {};

    return user && association && gsp
      ? {
          ...user,
          association: association ? association : {},
          gsp: gsp ? gsp : {},
        }
      : {};
  }
);
function formatDate(date) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var day = date.getUTCDate();
  var monthIndex = date.getUTCMonth();
  var year = date.getUTCFullYear();
  return monthNames[monthIndex] + " " + day + ", " + year;
}

const getWaterYear = createSelector((session, id) => {
  let wy = session.WaterYear.filter(
    (y) => y.is_active === true
  ).toRefArray()[0];
  return {
    ...wy,
  };
});

const currentSurfaceWaterSupply = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["gsp"]),
    sws_id: parseInt(ownProps.match.params["id"]),
  }),
  (session, options) => {
    let gsp = session.GSP.filter((u) => u.id === options.gsp).toRefArray()[0];

    let thisWaterYear = session.WaterYear.filter(
      (y) => y.is_active === true
    ).toRefArray()[0];

    if (options.sws_id) {
      let sws = session.SurfaceWaterSupply.filter(
        (y) => y.gsp === gsp.id && y.id === options.sws_id
      ).toRefArray()[0];

      let wy =
        sws && sws.water_year_id
          ? session.WaterYear.filter(
              (y) => y.id === sws.water_year_id
            ).toRefArray()[0]
          : {};

      sws = { ...sws, wy: wy };

      return gsp && sws && wy ? sws : {};
    } else {
      return gsp && thisWaterYear
        ? session.SurfaceWaterSupply.filter(
            (y) => y.water_year_id === thisWaterYear.id && y.gsp === gsp.id
          ).toRefArray()[0]
        : {};
    }
  }
);
const historicSurfaceWaterSupply = createSelector(
  (state, ownProps) => ({
    id: state.auth && state.auth.user ? state.auth.user.id : {},
    gsp: parseInt(ownProps.match.params["gsp"]),
  }),
  (session, options) => {
    let association = session.GSPAssociation.filter(
      (u) => u.user === options.id && u.gsp === options.gsp
    ).toRefArray()[0];

    let gsp = association
      ? session.GSP.filter((u) => u.id === association.gsp).toRefArray()[0]
      : {};

    let thisWaterYear = session.WaterYear.filter(
      (y) => y.is_active === true
    ).toRefArray()[0];

    return thisWaterYear && gsp
      ? session.SurfaceWaterSupply.filter(
          (y) => y.water_year_id !== thisWaterYear.id && y.gsp === gsp.id
        ).toRefArray()
      : {};
  }
);
const styles = (theme) => ({
  container: {
    paddingLeft: "10px",
  },
  hrclass: {
    borderColor: "primary",
  },
  tc: {
    padding: "4px 4px 4px 4px",
    maxWidth: "60px",
  },
  tcNumber: {
    padding: "4px 4px 4px 4px",
    maxWidth: "40px",
  },
  rightAligned: {
    textAlign: "right",
    padding: "4px 4px 4px 4px",
    maxWidth: "40px",
  },
  tcAccuracy: {
    padding: "4px 4px 4px 15px",
    maxWidth: "20px",
  },
  mdHeader: {
    fontSize: "18px",
    textDecoration: "underline",
    fontWeight: "normal",
  },
  lgHeader: {
    fontSize: "25px",
  },
});
class ReportingSWSupply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csrftoken: localStorage.auth_token,
      loader: null,
      agency: "Not Available",
      waterYearStart: "Not Available",
      waterYearEnd: "Not Available",
      districtName: "Not Available",
      helpOpen: false,
      //totals
      import_cv_total: "",
      import_sw_total: "",
      import_l_total: "",
      recycled_total: "",
      desalination_total: "",
      other_other_total: "",
      riv_total: "",
      creek_total: "",
      total_total: "",
      // Default Values
      sw_agg_import_cv: 0,
      sw_agg_import_sw: 0,
      sw_agg_import_l: 0,
      sw_agg_recycled: 0,
      sw_agg_desalination: 0,
      sw_agg_other: 0,
      sw_agg_riv: 0,
      sw_agg_creek: 0,
      sw_agg_total: 0,
      sw_dom_import_cv: 0,
      sw_dom_import_sw: 0,
      sw_dom_import_l: 0,
      sw_dom_recycled: 0,
      sw_dom_desalination: 0,
      sw_dom_other: 0,
      sw_dom_riv: 0,
      sw_dom_creek: 0,
      sw_dom_total: 0,
      sw_ind_import_cv: 0,
      sw_ind_import_sw: 0,
      sw_ind_import_l: 0,
      sw_ind_recycled: 0,
      sw_ind_desalination: 0,
      sw_ind_other: 0,
      sw_ind_riv: 0,
      sw_ind_creek: 0,
      sw_ind_total: 0,
      sw_wet_import_cv: 0,
      sw_wet_import_sw: 0,
      sw_wet_import_l: 0,
      sw_wet_recycled: 0,
      sw_wet_desalination: 0,
      sw_wet_other: 0,
      sw_wet_riv: 0,
      sw_wet_creek: 0,
      sw_wet_total: 0,
      sw_out_import_cv: 0,
      sw_out_import_sw: 0,
      sw_out_import_l: 0,
      sw_out_recycled: 0,
      sw_out_desalination: 0,
      sw_out_other: 0,
      sw_out_riv: 0,
      sw_out_creek: 0,
      sw_out_total: 0,
      sw_veg_import_cv: 0,
      sw_veg_import_sw: 0,
      sw_veg_import_l: 0,
      sw_veg_recycled: 0,
      sw_veg_desalination: 0,
      sw_veg_other: 0,
      sw_veg_riv: 0,
      sw_veg_creek: 0,
      sw_veg_total: 0,
      sw_other_import_cv: 0,
      sw_other_import_sw: 0,
      sw_other_import_l: 0,
      sw_other_recycled: 0,
      sw_other_desalination: 0,
      sw_other_other: 0,
      sw_other_riv: 0,
      sw_other_creek: 0,
      sw_other_total: 0,
      water_year_id: null,
      gsp_id: null,
      curdata: false,
      callOptions: false,

      sw_gsp_import_cv_total: 0,
      sw_gsp_import_sw_total: 0,
      sw_gsp_import_l_total: 0,
      sw_gsp_riv_total: 0,
      sw_gsp_creek_total: 0,
      sw_gsp_recycled_total: 0,
      sw_gsp_desalination_total: 0,
      sw_gsp_other_total: 0,
      sw_gsp_total: 0,

      editable: false,
    };
  }
  componentDidMount() {
    const { authState, history } = this.props;
    // if (authState && authState.user && authState.user.role === "Agency") {
    //   history.push("/dashboard");
    // }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    /* Auto-calculating fields after they're updated */
    const {
      sw_agg_import_cv,
      sw_agg_import_sw,
      sw_agg_import_l,
      sw_agg_recycled,
      sw_agg_desalination,
      sw_agg_other,
      sw_agg_riv,
      sw_agg_creek,
      sw_dom_import_cv,
      sw_dom_import_sw,
      sw_dom_import_l,
      sw_dom_recycled,
      sw_dom_desalination,
      sw_dom_other,
      sw_dom_riv,
      sw_dom_creek,
      sw_ind_import_cv,
      sw_ind_import_sw,
      sw_ind_import_l,
      sw_ind_recycled,
      sw_ind_desalination,
      sw_ind_other,
      sw_ind_riv,
      sw_ind_creek,
      sw_wet_import_cv,
      sw_wet_import_sw,
      sw_wet_import_l,
      sw_wet_recycled,
      sw_wet_desalination,
      sw_wet_other,
      sw_wet_riv,
      sw_wet_creek,
      sw_out_import_cv,
      sw_out_import_sw,
      sw_out_import_l,
      sw_out_recycled,
      sw_out_desalination,
      sw_out_other,
      sw_out_riv,
      sw_out_creek,
      sw_veg_import_cv,
      sw_veg_import_sw,
      sw_veg_import_l,
      sw_veg_recycled,
      sw_veg_desalination,
      sw_veg_other,
      sw_veg_riv,
      sw_veg_creek,
      sw_other_import_cv,
      sw_other_import_sw,
      sw_other_import_l,
      sw_other_recycled,
      sw_other_desalination,
      sw_other_other,
      sw_other_riv,
      sw_other_creek,
      sw_agg_total,
      sw_dom_total,
      sw_ind_total,
      sw_wet_total,
      sw_out_total,
      sw_veg_total,
      sw_other_total,

      sw_gsp_import_cv_total,
      sw_gsp_import_sw_total,
      sw_gsp_import_l_total,
      sw_gsp_riv_total,
      sw_gsp_creek_total,
      sw_gsp_recycled_total,
      sw_gsp_desalination_total,
      sw_gsp_other_total,
      sw_gsp_total,
    } = this.state;
    // Totals
    // if (
    //   prevState.sw_agg_import_cv !== sw_agg_import_cv ||
    //   prevState.sw_dom_import_cv !== sw_dom_import_cv ||
    //   prevState.sw_ind_import_cv !== sw_ind_import_cv ||
    //   prevState.sw_wet_import_cv !== sw_wet_import_cv ||
    //   prevState.sw_out_import_cv !== sw_out_import_cv ||
    //   prevState.sw_veg_import_cv !== sw_veg_import_cv ||
    //   prevState.sw_other_import_cv !== sw_other_import_cv
    // ) {
    //   this.setState({
    //     import_cv_total:
    //       this.pf(sw_agg_import_cv) +
    //       this.pf(sw_dom_import_cv) +
    //       this.pf(sw_ind_import_cv) +
    //       this.pf(sw_wet_import_cv) +
    //       this.pf(sw_out_import_cv) +
    //       this.pf(sw_veg_import_cv) +
    //       this.pf(sw_other_import_cv),
    //   });
    // }
    // if (
    //   prevState.sw_agg_import_sw !== sw_agg_import_sw ||
    //   prevState.sw_dom_import_sw !== sw_dom_import_sw ||
    //   prevState.sw_ind_import_sw !== sw_ind_import_sw ||
    //   prevState.sw_wet_import_sw !== sw_wet_import_sw ||
    //   prevState.sw_out_import_sw !== sw_out_import_sw ||
    //   prevState.sw_veg_import_sw !== sw_veg_import_sw ||
    //   prevState.sw_other_import_sw !== sw_other_import_sw
    // ) {
    //   this.setState({
    //     import_sw_total:
    //       this.pf(sw_agg_import_sw) +
    //       this.pf(sw_dom_import_sw) +
    //       this.pf(sw_ind_import_sw) +
    //       this.pf(sw_wet_import_sw) +
    //       this.pf(sw_out_import_sw) +
    //       this.pf(sw_veg_import_sw) +
    //       this.pf(sw_other_import_sw),
    //   });
    // }
    // if (
    //   prevState.sw_agg_import_l !== sw_agg_import_l ||
    //   prevState.sw_dom_import_l !== sw_dom_import_l ||
    //   prevState.sw_ind_import_l !== sw_ind_import_l ||
    //   prevState.sw_wet_import_l !== sw_wet_import_l ||
    //   prevState.sw_out_import_l !== sw_out_import_l ||
    //   prevState.sw_veg_import_l !== sw_veg_import_l ||
    //   prevState.sw_other_import_l !== sw_other_import_l
    // ) {
    //   this.setState({
    //     import_l_total:
    //       this.pf(sw_agg_import_l) +
    //       this.pf(sw_dom_import_l) +
    //       this.pf(sw_ind_import_l) +
    //       this.pf(sw_wet_import_l) +
    //       this.pf(sw_out_import_l) +
    //       this.pf(sw_veg_import_l) +
    //       this.pf(sw_other_import_l),
    //   });
    // }
    // if (
    //   prevState.sw_agg_recycled !== sw_agg_recycled ||
    //   prevState.sw_dom_recycled !== sw_dom_recycled ||
    //   prevState.sw_ind_recycled !== sw_ind_recycled ||
    //   prevState.sw_wet_recycled !== sw_wet_recycled ||
    //   prevState.sw_out_recycled !== sw_out_recycled ||
    //   prevState.sw_veg_recycled !== sw_veg_recycled ||
    //   prevState.sw_other_recycled !== sw_other_recycled
    // ) {
    //   this.setState({
    //     recycled_total:
    //       this.pf(sw_agg_recycled) +
    //       this.pf(sw_dom_recycled) +
    //       this.pf(sw_ind_recycled) +
    //       this.pf(sw_wet_recycled) +
    //       this.pf(sw_out_recycled) +
    //       this.pf(sw_veg_recycled) +
    //       this.pf(sw_other_recycled),
    //   });
    // }
    // if (
    //   prevState.sw_agg_desalination !== sw_agg_desalination ||
    //   prevState.sw_dom_desalination !== sw_dom_desalination ||
    //   prevState.sw_ind_desalination !== sw_ind_desalination ||
    //   prevState.sw_wet_desalination !== sw_wet_desalination ||
    //   prevState.sw_out_desalination !== sw_out_desalination ||
    //   prevState.sw_veg_desalination !== sw_veg_desalination ||
    //   prevState.sw_other_desalination !== sw_other_desalination
    // ) {
    //   this.setState({
    //     desalination_total:
    //       this.pf(sw_agg_desalination) +
    //       this.pf(sw_dom_desalination) +
    //       this.pf(sw_ind_desalination) +
    //       this.pf(sw_wet_desalination) +
    //       this.pf(sw_out_desalination) +
    //       this.pf(sw_veg_desalination) +
    //       this.pf(sw_other_desalination),
    //   });
    // }
    // if (
    //   prevState.sw_agg_other !== sw_agg_other ||
    //   prevState.sw_dom_other !== sw_dom_other ||
    //   prevState.sw_ind_other !== sw_ind_other ||
    //   prevState.sw_wet_other !== sw_wet_other ||
    //   prevState.sw_out_other !== sw_out_other ||
    //   prevState.sw_veg_other !== sw_veg_other ||
    //   prevState.sw_other_other !== sw_other_other
    // ) {
    //   this.setState({
    //     other_total:
    //       this.pf(sw_agg_other) +
    //       this.pf(sw_dom_other) +
    //       this.pf(sw_ind_other) +
    //       this.pf(sw_wet_other) +
    //       this.pf(sw_out_other) +
    //       this.pf(sw_veg_other) +
    //       this.pf(sw_other_other),
    //   });
    // }
    // if (
    //   prevState.sw_agg_riv !== sw_agg_riv ||
    //   prevState.sw_dom_riv !== sw_dom_riv ||
    //   prevState.sw_ind_riv !== sw_ind_riv ||
    //   prevState.sw_wet_riv !== sw_wet_riv ||
    //   prevState.sw_out_riv !== sw_out_riv ||
    //   prevState.sw_veg_riv !== sw_veg_riv ||
    //   prevState.sw_other_riv !== sw_other_riv
    // ) {
    //   this.setState({
    //     riv_total:
    //       this.pf(sw_agg_riv) +
    //       this.pf(sw_dom_riv) +
    //       this.pf(sw_ind_riv) +
    //       this.pf(sw_wet_riv) +
    //       this.pf(sw_out_riv) +
    //       this.pf(sw_veg_riv) +
    //       this.pf(sw_other_riv),
    //   });
    // }
    // if (
    //   prevState.sw_agg_creek !== sw_agg_creek ||
    //   prevState.sw_dom_creek !== sw_dom_creek ||
    //   prevState.sw_ind_creek !== sw_ind_creek ||
    //   prevState.sw_wet_creek !== sw_wet_creek ||
    //   prevState.sw_out_creek !== sw_out_creek ||
    //   prevState.sw_veg_creek !== sw_veg_creek ||
    //   prevState.sw_other_creek !== sw_other_creek
    // ) {
    //   this.setState({
    //     creek_total:
    //       this.pf(sw_agg_creek) +
    //       this.pf(sw_dom_creek) +
    //       this.pf(sw_ind_creek) +
    //       this.pf(sw_wet_creek) +
    //       this.pf(sw_out_creek) +
    //       this.pf(sw_veg_creek) +
    //       this.pf(sw_other_creek),
    //   });
    // }
    // if (
    //   prevState.sw_agg_total !== sw_agg_total ||
    //   prevState.sw_dom_total !== sw_dom_total ||
    //   prevState.sw_ind_total !== sw_ind_total ||
    //   prevState.sw_wet_total !== sw_wet_total ||
    //   prevState.sw_out_total !== sw_out_total ||
    //   prevState.sw_veg_total !== sw_veg_total ||
    //   prevState.sw_other_total !== sw_other_total
    // ) {
    //   this.setState({
    //     total_total:
    //       this.pf(sw_agg_total) +
    //       this.pf(sw_dom_total) +
    //       this.pf(sw_ind_total) +
    //       this.pf(sw_wet_total) +
    //       this.pf(sw_out_total) +
    //       this.pf(sw_veg_total) +
    //       this.pf(sw_other_total),
    //   });
    // }
  }
  //Check for: user && user.role === "GSA Representative" ?

  populateStateFromHistory = () => {
    const { watercur } = this.props;
    if (watercur && watercur.id && !this.state.curdata) {
      this.setState({
        ...this.state,
        ...watercur,
        curdata: true,
      });
    }
  };
  setAuthorized = () => {
    const { user } = this.props;

    let editable = user && user.role === "Administrator";
    if (this.state.editable !== editable) {
      this.setState({ editable: editable });
    }
  };

  pf = (val) => {
    if (val === "-" || val === "" || val === " ") {
      return parseFloat(0);
    } else if (typeof val === "string") {
      return parseFloat(val.replace(/,/g, ""));
    } else {
      return parseFloat(val);
    }
  };
  MAP_OPTIONS = (table) => {
    var t = table.map((row) => ({
      label: row.display_name,
      value: row.value,
    }));
    return t;
  };
  NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
              name: other.name,
            },
          });
        }}
        thousandSeparator
      />
    );
  };
  handleChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  };
  handleChangeNumeric = (evt) => {
    const re = /^-?[-0-9\b]+$/;
    const val = evt.target.value.replace(/,/g, "");
    if (val === "" || re.test(val)) {
      this.setState({
        [evt.target.name]: val,
      });
    }
  };
  handleSave = (evt) => {
    evt.preventDefault();
    const { ormSurfaceWaterSupplyCreate, ormSurfaceWaterSupplyUpdate } =
      this.props;
    const { user, watercur, history } = this.props;
    const { curdata } = this.state;
    this.setState({
      submitted: true,
      saved: true,
      water_year_id: watercur.wy.id, // wateryr.id,
      gsp_id: user.gsp.id,
    });
    const stObj = {
      ...this.state,
      submitted: true,
      saved: true,
      water_year_id: watercur.wy.id,
      gsp_id: user.gsp.id,
    };
    if (!curdata) {
      ormSurfaceWaterSupplyCreate(stObj);
    } else {
      ormSurfaceWaterSupplyUpdate(stObj);
    }
    history.push("/surfacewatersupply");
  };
  // submitForm = (evt) => {
  //   evt.preventDefault();
  //   const { ormSurfaceWaterSupplyUpdate, history } = this.props;
  //   this.setState({
  //     submitted: true,
  //   });
  //   const stObj = {
  //     ...this.state,
  //     submitted: true,
  //   };
  //   ormSurfaceWaterSupplyUpdate(stObj);
  //   history.push("/surfacewatersupply");
  // };
  render() {
    this.setAuthorized();
    this.populateStateFromHistory();
    const {
      import_cv_total,
      import_sw_total,
      import_l_total,
      recycled_total,
      desalination_total,
      other_total,
      riv_total,
      creek_total,
      total_total,
      helpOpen,
      siteChoices,
      callOptions,
      editable,
    } = this.state;
    const { classes, user, wateryr, watercur, waterhist, optionsReq } =
      this.props;
    //OPTIONS comes back as a promise
    if (optionsReq && !siteChoices) {
      optionsReq.then((data) => {
        if (!this.state.siteChoices) {
          this.setState({
            siteChoices: data,
          });
        }
      });
    }
    return (
      <AppContainer authenticated>
        <div className={classes.container}>
          <form>
            {watercur && watercur.wy ? (
              <>
                <BreadcrumbNav
                  level1="Surface Water Supply"
                  level1link="/surfacewatersupply"
                  level2="Surface Water Supply Details"
                  level2link={
                    "/reporting/swsupply/" +
                    this.props.match.params["gsp"] +
                    "/" +
                    this.props.match.params["id"]
                  }
                />
                <br />
              </>
            ) : (
              <>
                <BreadcrumbNav
                  level1="Associated GSP's"
                  level1link="/reporting"
                  level2="Report Surface Water Supply Data"
                  level2link={
                    "/reporting/swsupply/" + this.props.match.params["gsp"]
                  }
                />
                <br />
              </>
            )}

            <Grid container spacing={24}>
              <Grid item xs={12}>
                {watercur && watercur.wy ? (
                  <>
                  <span className={classes.lgHeader}>
                    Water Year {watercur.wy.water_year}:{" "}
                    {watercur.wy.water_year_start
                      ? new Date(
                          watercur.wy.water_year_start + " "
                        ).toLocaleDateString("en-US")
                      : "Start Date Not Specified"}{" "}
                    -{" "}
                    {watercur.wy.water_year_start
                      ? new Date(
                          watercur.wy.water_year_end + " "
                        ).toLocaleDateString("en-US")
                      : "End Date Not Specified"}
                  </span>
                </>
                ) : (
                  <>
                    <hr className={classes.hrclass} />
                    <span className={classes.lgHeader}>
                      Current Water Year {wateryr ? wateryr.water_year : ""}:{" "}
                      {wateryr
                        ? new Date(
                            wateryr.water_year_start + " "
                          ).toLocaleDateString("en-US")
                        : ""}{" "}
                      -{" "}
                      {wateryr
                        ? new Date(
                            wateryr.water_year_end + " "
                          ).toLocaleDateString("en-US")
                        : ""}
                    </span>
                  </>
                )}
              </Grid>

              <hr />
              <Grid item xs={12}>
                <hr className={classes.hrclass} />
                <h2 className={classes.mdHeader}>
                  Surface Water Supply Data
                  <HelpLabel
                    open={helpOpen}
                    question="Surface Water Supply"
                    showHelp={true}
                    inputLabel={true}
                    helpText={
                      <>
                        Water supplies used in the San Gorgonio Pass Subbasin
                        either diverted from a surface water body in or adjacent
                        to the Subbasin and/or imported from outside the
                        Subbasin and delivered to each specific water use
                        sector.
                      </>
                    }
                  />
                </h2>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Water Source Type
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Total
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Imported - Central Valley Project{" "}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Imported - State Water Project{" "}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Imported - Local Imported Supplies{" "}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Local Supplies{/*  - River */}
                        <HelpLabel
                          open={helpOpen}
                          question="Rivers"
                          showHelp={true}
                          inputLabel={true}
                          helpText={<>San Joaquin River and/or Kings River.</>}
                        />
                      </TableCell>
                      {/* <TableCell xs={1} className={classes.tcNumber}>
                        Local Supplies - Creek
                        <HelpLabel
                          open={helpOpen}
                          question="Creeks"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              All other natural surface water courses that are
                              not the San Joaquin or Kings River.
                            </>
                          }
                        />
                      </TableCell> */}
                      <TableCell xs={1} className={classes.tcNumber}>
                        Recycled Water
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Desalination
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Other
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        Other Description
                      </TableCell>
                      {/* <TableCell xs={2} className={classes.tc}>
                        Source Notes
                      </TableCell> */}
                      <TableCell xs={3} className={classes.tc}>
                        Explain methods used to calculate surface water supply
                      </TableCell>
                      {/* <TableCell xs={1} className={classes.tcAccuracy}>
                        Calculation Accuracy
                      </TableCell> */}
                    </TableRow>
                    {/* <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Urban / Domestic:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_dom_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_dom_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_dom_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_dom_calcm"
                            value={this.state.sw_dom_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_dom_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_dom_acc"
                            value={this.state.sw_dom_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_dom_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Industrial:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_ind_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_ind_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_ind_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_ind_calcm"
                            value={this.state.sw_ind_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_ind_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_ind_acc"
                            value={this.state.sw_ind_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_ind_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Agricultural:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_agg_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_agg_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_agg_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_agg_calcm"
                            value={this.state.sw_agg_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_agg_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_agg_acc"
                            value={this.state.sw_agg_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_agg_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Managed Wetlands:
                        <HelpLabel
                          open={helpOpen}
                          question="Managed Wetlands"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              Volume of Surface water supplies delivered (in
                              acre-feet) for programmatic management of
                              environmental uses, such as duck clubs, wildlife
                              refuges/areas, etc.
                            </>
                          }
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_wet_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_wet_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_wet_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_wet_calcm"
                            value={this.state.sw_wet_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_wet_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_wet_acc"
                            value={this.state.sw_wet_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_wet_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Outside Subbasin:
                        <HelpLabel
                          open={helpOpen}
                          question="Outside Subbasin"
                          showHelp={true}
                          inputLabel={true}
                          helpText={
                            <>
                              Volume of surface water supplies diverted from
                              within the Subbasin and transferred outside the
                              Subbasin (in acre-feet).
                            </>
                          }
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_out_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_out_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_out_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_out_calcm"
                            value={this.state.sw_out_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_out_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_out_acc"
                            value={this.state.sw_out_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_out_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Native Vegetation:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_veg_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_veg_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_veg_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_veg_calcm"
                            value={this.state.sw_veg_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_veg_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_veg_acc"
                            value={this.state.sw_veg_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_veg_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Other:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_import_cv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_import_cv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_import_sw"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_import_sw}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_import_l"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_import_l}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_riv"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_riv}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_creek"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_creek}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_recycled"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_recycled}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_desalination"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_desalination}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_other"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_other}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={2} className={classes.tc}>
                        <TextField
                          name="sw_other_other_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_other_desc}
                          onChange={this.handleChange}
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_total"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_total}
                          onBlur={this.handleChangeNumeric}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_other_notes"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        {siteChoices ? (
                          <Select
                            name="sw_other_calcm"
                            value={this.state.sw_other_calcm}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_other_calcm.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                      <TableCell xs={1} className={classes.tcAccuracy}>
                        {siteChoices ? (
                          <Select
                            name="sw_other_acc"
                            value={this.state.sw_other_acc}
                            onChange={this.handleChange}
                            disabled={submitted || !authorized}
                            fullWidth
                          >
                            {this.MAP_OPTIONS(
                              siteChoices.sw_other_acc.choices
                            ).map((o, i) => (
                              <MenuItem key={i} value={o.value}>
                                {o.label}
                              </MenuItem>
                            ))}
                          </Select>
                        ) : (
                          <div />
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell xs={3} className={classes.tc}>
                        Other Use Description:
                      </TableCell>
                      <TableCell
                        xs={3}
                        className={classes.tcNumber}
                        colSpan={6}
                      >
                        <TextField
                          name="sw_other_use_desc"
                          disabled={submitted || !authorized}
                          value={this.state.sw_other_use_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={2} className={classes.tc}></TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={2} className={classes.tc}></TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={2} className={classes.tc}></TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell>
                    </TableRow> */}
                    <TableRow>
                      <TableCell xs={2} className={classes.tcNumber}>
                        Total:
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_total"
                          value={this.state.sw_gsp_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_import_cv_total"
                          value={this.state.sw_gsp_import_cv_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_import_sw_total"
                          value={this.state.sw_gsp_import_sw_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_import_l_total"
                          value={this.state.sw_gsp_import_l_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_riv_total"
                          value={this.state.sw_gsp_riv_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_creek_total"
                          value={this.state.sw_gsp_creek_total}
                          disabled={submitted || !authorized}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell> */}
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_recycled_total"
                          value={this.state.sw_gsp_recycled_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_desalination_total"
                          value={this.state.sw_gsp_desalination_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={1} className={classes.tcNumber}>
                        <TextField
                          name="sw_gsp_other_total"
                          value={this.state.sw_gsp_other_total}
                          onChange={this.handleChangeNumeric}
                          disabled={!editable}
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">AF</InputAdornment>
                            ),
                            inputComponent: this.NumberFormatCustom,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        <TextField
                          name="sw_gsp_other_desc"
                          disabled={!editable}
                          value={this.state.sw_gsp_other_desc}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      <TableCell xs={3} className={classes.tc}>
                        <TextField
                          name="sw_gsp_total_notes"
                          disabled={!editable}
                          value={this.state.sw_gsp_total_notes}
                          onChange={this.handleChange}
                          fullWidth
                          multiline={true}
                          rows={1}
                          rowsMax="4"
                          inputProps={{
                            maxLength: 4000,
                          }}
                        />
                      </TableCell>
                      {/* <TableCell xs={3} className={classes.tc}></TableCell>
                      <TableCell xs={3} className={classes.tc}></TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              {editable && (
                <>
                  <Grid item xs={false} sm={8} md={9} lg={10}></Grid>
                  <Grid item xs={12} sm={4} md={3} lg={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="button"
                      label="Save"
                      onClick={this.handleSave}
                      color="primary"
                    >
                      Save
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </div>
      </AppContainer>
    );
  }
}
ReportingSWSupply = connect(
  (state, ownProps) => ({
    optionsReq: getOptions(state, ownProps),
    wateryr: getWaterYear(state, ownProps),
    user: getUserData(state, ownProps),
    watercur: currentSurfaceWaterSupply(state, ownProps),
    waterhist: historicSurfaceWaterSupply(state, ownProps),
    authState: state.auth,
  }),
  {
    ...SurfaceWaterSupply.actions,
    ...authActions,
    ...navActions,
  }
)(ReportingSWSupply);
export default withStyles(styles)(withRouter(ReportingSWSupply));
